import React, {Component} from "react";
import {bindActionCreators, compose} from "redux";
import {browserHistory} from "react-router";
import {connect} from "react-redux";
import {Col, FormGroup, Row} from "react-bootstrap";
import * as W25F2033Actions from "../../../../redux/W2X/W25F2033/W25F2033_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonCustom from "../../../common/button/button-custom";
import UserImage from "../../../common/user/user-image";
import Config from "../../../../config";
import Api from "../../../../services/api";
import Icons from "../../../common/icons";
import {
    Button,
    ButtonGroup,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Icon,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    withStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import CandidateProFileCard from "../Components/CandidateProFileCard";
import CandidateCVCard from "../Components/CandidateCVCard";
import W25F2012 from "../W25F2012/W25F2012";
import CandidateMailExchangeCard from "../Components/CandidateMailExchangeCard";
import History from "../../../libs/history";
import W25F2303 from "../W25F2303/W25F2303";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => {
    return {
        containerCard: {
            padding: "15px 0",
            backgroundColor: "rgba(226,226,229,0.75)"
        },
        expansionCardNoBoxShadow: {
            "&.MuiPaper-elevation1": {
                boxShadow: "none",
            },
            "& .MuiExpansionPanelSummary-content": {
                margin: 0,
            },
            "& .MuiExpansionPanelSummary-content.Mui-expanded": {
                margin: 0,
            },
        },
        expansionSummaryRoot: {
            minHeight: "45px !important",
            "& .MuiTypography-h6": {
                color: theme.palette.text.primary,
                fontSize: "1.2rem !important",
                fontWeight: "600 !important",
            },
        },
        expansionSummaryContent: {
            margin: "0 !important",
            "&.withSubTitle": {
                flexDirection: "column",
                padding: "10px 0",
            },
        },
        expansionDetailsRoot: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "8px 24px 15px"
        },
        expansionAction: {
            borderTop: "1px solid #E4E9F2",
            paddingTop: 10
        },
        expansionContent: {
            flexGrow: 1,
            marginBottom: 15
        },
        boxShadowCard: {
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        },
        cardOuter: {
            display: "flex",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            }
        },
        cardInterviewInfo: {
            marginRight: "12px !important",
            [theme.breakpoints.down("sm")]: {
                marginRight: "0 !important",
                marginBottom: "8px !important"
            },
        },
        cardInterviewer: {
            marginLeft: "12px !important",
            [theme.breakpoints.down("sm")]: {
                marginLeft: "0 !important",
                marginTop: "8px !important"
            },
        },
        cardInner: {
            display: "flex",
            flexDirection: "column",
            width: "49%",
            borderRadius: 4,
            "&.MuiAccordion-root.Mui-expanded": {
                margin: 0
            },
            "& .MuiCollapse-entered": {
                height: "100% !important",
                "& .MuiCollapse-wrapper": {
                    height: "100%",
                    "& .MuiCollapse-wrapperInner > :first-child": {
                        height: "100%"
                    }
                }

            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
        cardInnerLoading: {
            "& .MuiCollapse-container.MuiCollapse-entered": {
                "& .MuiCollapse-wrapper": {
                    height: "85%",
                }
            }
        },
        flexDRow: {
            display: "flex",
            alignItems: "center",
        },
        flexDColumn: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        },
        primaryText: {
            color: theme.palette.primary.main,
            fontWeight: 600,
            textTransform: "uppercase",
        },
        subText: {
            color: theme.palette.text.subtext,
            fontWeight: 400,
        },
        titleExpandDetails: {
            color: "#555768",
            fontWeight: 600,
            lineHeight: 2,
            textTransform: "uppercase",
            width: "100%",
            [theme.breakpoints.down("md")]: {
                textAlign: "center",
            },
        },
        btnGroup: {
            minWidth: 96,
        },
        buttonGroup: {
            width: "100%",
            marginLeft: "0px !important",
        },
        focusedBtnSuccess: {
            backgroundColor: "#EFF5FF",
            color: theme.palette.success.main,
        },
        btnSuccess: {
            width: "100%",
            "&:hover": {
                color: theme.palette.success.main,
            },
        },
        btnWarning: {
            width: "100%",
            "&:hover": {
                color: theme.palette.warning.main,
            },
        },
        focusedBtnWarning: {
            backgroundColor: "#EFF5FF",
            color: theme.palette.warning.main,
        },
        btnDanger: {
            width: "100%",
            "&:hover": {
                color: theme.palette.danger.main,
            },
        },
        focusedBtnDanger: {
            backgroundColor: "#EFF5FF",
            color: theme.palette.danger.main,
        },
        dashedBox: {
            padding: "21px 0",
            borderTopWidth: 3,
            borderTopColor: "rgba(150, 152, 162, 0.45)",
            borderTop: "dashed",
        },
        boxShadow: {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        fillLoading: {
            fill: theme.palette.primary.main + "!important",
        },
    };
};

class W25F2033 extends Component {
    constructor(props) {
        super(props);
        const {location} = this.props;
        this.state = {
            anchorEl: null,
            expandedCardGroup: true,
            isLoadingForm: false,
            isLoadingSendMailRemind: false,
            loadDataMail: false,
            showW25F2303Popup: false,
        };
        this.params = {
            FormID: "W25F2033",
            Language: Config.language || 84,
            CandidateID: _.get(location, "state.CandidateID", _.get(location,"state.Candidate_ID", "")),
            RecInfoID: _.get(location, "state.RecInfoID", _.get(location,"state.RecInfo_ID", "")),
            InterviewFileID: _.get(location, "state.InterviewFileID", _.get(location,"state.voucher_id", "")),
            InterviewTitle: _.get(location, "state.InterviewTitle", ""),
            RecInfoTitle: _.get(location, "state.RecInfoTitle", "")
        };
        this.refSectionMail = React.createRef();
        if (!this.params.CandidateID) browserHistory.push(Config.getRootPath() + "W25F2010");
        this.timerLoadingForm = null;
        this.timerLoadingDataMail = null;
    }

    clsx = (...spread) => _.join(spread, " ");

    renderUserImageHover = (user) => {
        const arrUserInfo = _.filter([
            {
                label: Config.lang('Nhan_vien'),
                value: _.join(_.compact(
                    [
                        _.get(user, "EmployeeID", ""),
                        _.get(user, "EmployeeName", "")
                    ]), " - ")
            },
            {
                label: Config.lang('Phong_ban'),
                value: _.get(user, "DepartmentName", "")
            },
            {
                label: Config.lang('Du_an'),
                value: _.get(user, "ProjectName", "")
            },
            {
                label: Config.lang('Chuc_vu'),
                value: _.get(user, "DutyName", "")
            },
            {
                label: Config.lang('Ngay_vao_lam'),
                value: _.get(user, "dateJoined", "")
            }
        ], item => Boolean(item.value))
        return _.map(arrUserInfo, (item, i) => <span key={i}>{item.label}: {item.value}<br/></span>)
    }

    loadForm = () => {
        if (this.timerLoadingForm) clearTimeout(this.timerLoadingForm);
        this.setState({isLoadingForm: true})
        this.props.generalActions.getCandidateProfile(this.params, (err) => {
            this.timerLoadingForm = setTimeout(() => {
                this.setState({isLoadingForm: false})
            }, 1000)
            if (err) Config.popup2.show("ERROR", err);
        })
    }

    onBackToScreen = () => {
        const {location} = this.props;
        const screenID = _.get(location, "state.ScreenID", "");
        if (!screenID) browserHistory.goBack();
        browserHistory.push({
            pathname: Config.getRootPath() + screenID,
            state: _.pick(this.params, ["RecInfoID", "RecInfoTitle"])
        });
    }

    onOpenMenu = (e) => {
        this.setState({anchorEl: _.get(e, "currentTarget", null)});
    };

    onCloseMenu = () => {
        this.setState({anchorEl: null});
    };

    onMailRemind = () => {
        const {getCandidateProfile} = this.props;
        this.props.w25f2033Actions.getMailTemplateRemind((err, data) => {
            if (err) {
                Config.popup2.show("ERROR", err);
                return false;
            }
            if (data) {
                let tempContentMail = _.get(data, "[0]ContentMail", "");
                const arrDataFieldMailRemind = [
                    {
                        fieldName: "CandidateName",
                        value: _.get(getCandidateProfile, "CandidateName", "")
                    },
                    {
                        fieldName: "CompanyName",
                        value: _.get(getCandidateProfile, "CompanyName", "")
                    },
                    {
                        fieldName: "InterviewDateTime",
                        value: _.get(getCandidateProfile, "InterviewDateTime", "")
                    },
                    {
                        fieldName: "CompanyName",
                        value: _.get(getCandidateProfile, "CompanyName", "")
                    },
                ];
                const titleMail = _.get(data, "[0]TitleMail", "");
                const contentMail = _.reduce(arrDataFieldMailRemind, (acc, itemCurrent) => {
                    acc = _.replace(tempContentMail, `$\{${itemCurrent.fieldName}}`, itemCurrent.value);
                    tempContentMail = acc;
                    return acc;
                }, "");
                this.onSendMailRemind(titleMail, contentMail);
            }
        });
    }

    onSendMailRemind = (titleMail = "", contentMail = "") => {
        const {getCandidateProfile} = this.props;
        const CandidateEmail = _.get(getCandidateProfile, "CandidateEmail", "");
        const params = {
            moduleId: "W25",
            transactionId: "INTERVIEW",
            priority: "normal",
            to: [CandidateEmail],
            content: contentMail,
            subject: titleMail,
            params: JSON.stringify([]),
            key01: _.get(this.params, "CandidateID", "") || "",
            key02: _.get(this.params, "RecInfoID", "") || "",
            key03: _.get(this.params, "InterviewFileID", "") || "",
        };
        Config.popup2.show("YES_NO", Config.lang("Ban_co_muon_gui_mail_nhac_lich_cho_ung_vien_nay"), async () => {
            this.setState({isLoadingSendMailRemind: true});
            const res = await Api.post("/mail/send", params);
            this.setState({isLoadingSendMailRemind: false});
            const messageErr = _.get(res, "message", "");
            const data = _.get(res, "data", {})
            if (messageErr) {
                Config.popup2.show("ERROR", messageErr);
                return false;
            }
            if (!_.isEmpty(data)) {
                Config.notify.show("success", Config.lang("Gui_thanh_cong"), 2000);
                this.setState({loadDataMail: !this.state.loadDataMail})
            }
        })
    };

    onInterViewConfirm = (CanConfirmStatusID) => {
        Config.popup2.show("YES_NO", Config.lang("Ban_co_muon_thay_doi_trang_thai_ung_vien_xac_nhan"), async () => {
            const res = await Api.put("/w25f2033/interview-confirm", {...this.params, CanConfirmStatusID});
            const messageErr = _.get(res, "message", "");
            const data = _.get(res, "data", []);
            if (messageErr) Config.popup2.show("ERROR", messageErr);
            if (data) {
                this.loadForm();
            }
        });
    };

    onUpdateInterviewStatus = (InterviewStatusID) => {
        Config.popup2.show("YES_NO", Config.lang("Ban_co_muon_thay_doi_trang_thai_phong_van"), async () => {
            const res = await Api.put("/w25f2033/update-interview-status", {...this.params, InterviewStatusID,});
            const messageErr = _.get(res, "message", "");
            const data = _.get(res, "data", []);
            if (messageErr) {
                Config.popup2.show("ERROR", messageErr);
                return false;
            }
            if (data) this.loadForm();
        });
    };

    onOpenEditInterview = () => {
        this.setState({showW25F2303Popup: true}, () => {
            this.onCloseMenu()
        })
    }

    onCloseEditInterview = () => {
        this.setState({showW25F2303Popup: false}, () => {
            this.loadForm();
        })
    }

    onDeleteInterview = async () => {
        const {getCandidateProfile} = this.props;
        const data = {
            ReceivedDate: _.get(getCandidateProfile, "InterviewDate", ""),
            CandidateID: _.join(
                _.compact([
                    _.get(getCandidateProfile, "CandidateID", ""),
                    _.get(getCandidateProfile, "CandidateName", "")]),
                "-"),
            InterviewPlace: _.get(getCandidateProfile, "InterviewPlace", "")
        };

        const captions = {
            ReceivedDate: "thoi_gian_phong_van",
            CandidateID: "Ung_vien",
            InterviewPlace: "dia_diem_phong_van",
        };

        const options = {
            data,
            captions,
            action: 3,
            ModuleID: "W25",
            TransID: this.params.InterviewFileID,
            TransactionID: "INTERVIEW",
            TransactionName: "Lịch phỏng vấn",
        };
        Config.popup2.show("YES_NO", Config.lang("Ban_co_muon_xoa_lich_phong_van"), async () => {
            const res = await Api.delete("/w25f2033/delete-interview-file", this.params);
            const messageErr = _.get(res, "message", "");
            const data = _.get(res, "data", {});
            if (messageErr) {
                Config.popup2.show("ERROR", messageErr);
                return false;
            }
            if (!_.isEmpty(data)) {
                const history = new History(options);
                await history.save(() => {
                    this.onBackToScreen()
                });
            }

        })
    }

    onScrollToBottom = () => {
        if (this.refSectionMail.current) this.refSectionMail.current.scrollIntoView({behavior: 'smooth'});
    }

    renderInfoSkeletonIconWithLabel = (variant = "caption", wSkLabel = "100%") => {
        const {classes} = this.props;
        return (<Typography className={classes.flexDRow} component={"div"} variant={variant}>
            <Skeleton style={{marginRight: 5}} animation="wave" variant="circle" width={25} height={25}/>
            <Skeleton animation="wave" width={wSkLabel}/>
        </Typography>)
    }

    renderTextContentSkeleton = (nLine = 3) => _.map(Array(nLine), (_, i) => <Skeleton key={i}
                                                                                       animation={(i + 1) === nLine ? "pulse" : "wave"}
                                                                                       width={((i + 1) === nLine ? 90 : 100) + "%"}/>);

    renderInterviewInfo = () => {
        const {classes, getCandidateProfile} = this.props;
        const {isLoadingForm} = this.state;
        const recpositionName = _.get(getCandidateProfile, "RecpositionName", "");
        let interviewType = "";
        switch (_.toUpper(_.get(getCandidateProfile, "InterviewType", ""))) {
            case "VIDEO":
                interviewType = Config.lang("Phong_van_online");
                break;
            case "PHONE":
                interviewType = Config.lang("Phong_van_qua_dien_thoai");
                break;
            case "ONSITE":
                interviewType = Config.lang("Phong_van_tai_van_phong");
                break;
            default:
                break;
        }
        const interviewPlace = _.get(getCandidateProfile, "InterviewPlace", "");
        return (<>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Typography className={classes.titleExpandDetails}
                                        variant="subtitle1">
                                {Config.lang("Vi_tri_cong_viec")}
                            </Typography>
                            {!isLoadingForm ?
                                <Typography className={classes.flexDRow} component="div">
                                    <Icon
                                        style={{marginRight: 10}}
                                        fontSize="small"
                                        className="far fa-briefcase"
                                    />
                                    <Typography className={classes.primaryText}
                                                variant="subtitle1">
                                        {recpositionName}
                                    </Typography>
                                </Typography> : recpositionName ? this.renderInfoSkeletonIconWithLabel(_, "42%") : ""}
                        </Col>
                    </Row>
                </FormGroup>
                <Row>
                    <Col xs={6}>
                        <Typography className={classes.titleExpandDetails}
                                    variant="subtitle1">
                            {Config.lang("Loai_phong_van")}
                        </Typography>
                        {!isLoadingForm ?
                            <Typography className={classes.subText}
                                        variant="subtitle1">
                                {interviewType}
                            </Typography> : interviewType ? <Skeleton animation="wave"/> : ""}
                    </Col>
                    <Col xs={6}>
                        <Typography className={classes.titleExpandDetails}
                                    variant="subtitle1">
                            {Config.lang("Dia_diem")}
                        </Typography>
                        {!isLoadingForm ?
                            <Typography className={classes.flexDRow} component="div">
                                <Icon
                                    style={{marginRight: 10}}
                                    fontSize="small"
                                    className="far fa-map-marker-alt"
                                />
                                <Typography className={classes.subText}
                                            variant="subtitle1">
                                    {interviewPlace}
                                </Typography>
                            </Typography> : interviewPlace ? this.renderInfoSkeletonIconWithLabel(_, "88%") : ""}
                    </Col>
                </Row>
            </>
        )
    }

    renderInterviewer = () => {
        const {classes, getCandidateProfile} = this.props;
        const {isLoadingForm} = this.state;
        const interviewerList = _.chain(_.get(getCandidateProfile, "InterviewerList", ""))
            .split(',')
            .map(UserID => Config.getUser({UserID}))
            .compact()
            .value();
        return (
            <Row>
                <Col xs={12}>
                    <Typography className={classes.titleExpandDetails}
                                variant="subtitle1">
                        {Config.lang("Nguoi_phong_van")}
                    </Typography>
                    <Typography component="div"
                                style={{display: "flex", flexWrap: "wrap"}}>
                        {!isLoadingForm ? _.map(interviewerList, (item, i) => (
                            <UserImage
                                key={i}
                                keyExpr={"UserID"}
                                valueExpr={"UserID"}
                                renderItemHover={this.renderUserImageHover}
                                className={classes.boxShadow}
                                allowHover
                                width={60}
                                height={60}
                                data={item}
                            />
                        )) : _.map(Array(interviewerList.length), (_, i) => <Skeleton key={i}
                                                                                      style={{marginRight: 8}}
                                                                                      animation="wave"
                                                                                      variant="circle"
                                                                                      width={60}
                                                                                      height={60}/>)}
                    </Typography>
                </Col>
            </Row>
        )
    }

    renderQuestionContent = () => {
        const {classes, getCandidateProfile} = this.props;
        const {isLoadingForm} = this.state;
        const questionContent = _.get(getCandidateProfile, "QuestionContent", "");
        return (!isLoadingForm ?
                <div dangerouslySetInnerHTML={{__html: questionContent}} className={classes.subText}/> :
                questionContent ? this.renderTextContentSkeleton(questionContent.split(/\r\n|\r|\n/).length) : ""
        )
    }

    renderNoteInterviewer = () => {
        const {classes, getCandidateProfile} = this.props;
        const {isLoadingForm} = this.state;
        const noteInternal = _.get(getCandidateProfile, "NoteInteranal", "");
        return (!isLoadingForm ? <div dangerouslySetInnerHTML={{__html: noteInternal}}
                                      className={classes.subText}/> : noteInternal ? this.renderTextContentSkeleton(noteInternal.split(/\r\n|\r|\n/).length) : ""

        )
    }

    render() {
        const {
            anchorEl,
            expandedCardGroup,
            isLoadingForm,
            isLoadingSendMailRemind,
            loadDataMail,
            showW25F2303Popup
        } = this.state;
        const {classes, getCandidateProfile} = this.props;
        const {InterviewFileID, InterviewTitle} = this.params;
        const candidateName = _.get(getCandidateProfile, "CandidateName", "");
        const candidateID = _.get(this.params, "CandidateID", _.get(getCandidateProfile, "CandidateID", ""));
        const canConfirmStatusID = _.toUpper(_.get(getCandidateProfile, "CanConfirmStatusID", ""));
        const interviewStatusID = _.toUpper(_.get(getCandidateProfile, "InterviewStatusID", ""));
        return (
            <>
                {showW25F2303Popup && <W25F2303 data={getCandidateProfile} mode="edit" open={showW25F2303Popup}
                                                onClose={this.onCloseEditInterview}/>}
                <ActionToolbar style={{borderBottom: "0px !important"}} alignment="flex-end" title={candidateName}
                               onBack={this.onBackToScreen}>
                    <div>
                        <ButtonCustom
                            loading={isLoadingSendMailRemind}
                            disabled={isLoadingSendMailRemind}
                            name={Config.lang("Nhac_lich")}
                            color={"primary"}
                            variant={"outlined"}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            className={"mgr5"}
                            icon={<Icons name={"notification"}/>}
                            onClick={this.onMailRemind}

                        />
                        <ButtonCustom
                            name={Config.lang("Gui_email")}
                            color={"primary"}
                            variant={"outlined"}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            className={"mgr5"}
                            icon={<Icons name={"email"}/>}
                            onClick={this.onScrollToBottom}
                        />
                        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true"
                                    onClick={this.onOpenMenu}>
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.onCloseMenu}
                        >
                            <MenuItem onClick={this.onOpenEditInterview}>
                                {Config.lang("Sua_phong_van")}
                            </MenuItem>
                            <MenuItem onClick={this.onDeleteInterview}>
                                {Config.lang("Xoa_phong_van")}
                            </MenuItem>
                        </Menu>
                    </div>
                </ActionToolbar>
                <Row className={classes.containerCard}>
                    <Col xs={12} lg={8}>
                        <FormGroup>
                            <CandidateProFileCard isLoading={isLoadingForm} data={getCandidateProfile}/>
                        </FormGroup>
                        <FormGroup>
                            <div className={classes.cardOuter}>
                                <Accordion
                                    onChange={() => {
                                        this.setState({
                                            expandedCardGroup: !expandedCardGroup
                                        })
                                    }}
                                    expanded={expandedCardGroup}
                                    defaultExpanded
                                    className={this.clsx(classes.cardInner, classes.cardInterviewInfo, classes.boxShadowCard, isLoadingForm ? classes.cardInnerLoading : "")}>
                                    <AccordionSummary
                                        classes={{
                                            root: classes.expansionSummaryRoot,
                                            content: classes.expansionSummaryContent,
                                        }}
                                        expandIcon={<ExpandMoreIcon/>}
                                        IconButtonProps={{size: "small"}}
                                    >
                                        <Typography
                                            variant="h6">{Config.lang("Thong_tin_phong_van")}</Typography>
                                    </AccordionSummary>
                                    <Divider variant="middle"/>
                                    <AccordionDetails
                                        classes={{
                                            root: classes.expansionDetailsRoot,
                                        }}
                                    >
                                        <div className={classes.expansionContent}>
                                            {this.renderInterviewInfo()}
                                        </div>
                                        <div className={classes.expansionAction}>
                                            <Typography className={classes.titleExpandDetails}
                                                        variant="subtitle1">
                                                {Config.lang("Ung_vien_xac_nhan")}
                                            </Typography>
                                            <ButtonGroup
                                                className={classes.buttonGroup}
                                                classes={{grouped: classes.btnGroup}}
                                                size="small"
                                                variant="text"
                                            >
                                                <Button
                                                    onClick={() => this.onInterViewConfirm("YES")}
                                                    startIcon={<Icon className="far fa-check"/>}
                                                    className={[
                                                        classes.btnSuccess,
                                                        classes.subText,
                                                        canConfirmStatusID === "YES" ? classes.focusedBtnSuccess : "",
                                                    ].join(" ")}
                                                >
                                                    Yes
                                                </Button>
                                                <Button
                                                    onClick={() => this.onInterViewConfirm("MAYBE")}
                                                    startIcon={<Icon className="far fa-question"/>}
                                                    className={[
                                                        classes.btnWarning,
                                                        classes.subText,
                                                        canConfirmStatusID === "MAYBE" ? classes.focusedBtnWarning : "",
                                                    ].join(" ")}
                                                >
                                                    Maybe
                                                </Button>
                                                <Button
                                                    onClick={() => this.onInterViewConfirm("NO")}
                                                    startIcon={<Icon className="far fa-times"/>}
                                                    className={[
                                                        classes.btnDanger,
                                                        classes.subText,
                                                        canConfirmStatusID === "NO" ? classes.focusedBtnDanger : "",
                                                    ].join(" ")}
                                                >
                                                    No
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    onChange={() => {
                                        this.setState({
                                            expandedCardGroup: !expandedCardGroup
                                        })
                                    }}
                                    expanded={expandedCardGroup}
                                    defaultExpanded
                                    className={this.clsx(classes.cardInner, classes.cardInterviewer, classes.boxShadowCard, isLoadingForm ? classes.cardInnerLoading : "")}>
                                    <AccordionSummary
                                        classes={{
                                            root: classes.expansionSummaryRoot,
                                            content: classes.expansionSummaryContent,
                                        }}
                                        IconButtonProps={{size: "small"}}
                                        expandIcon={<ExpandMoreIcon/>}
                                    >
                                        <Typography
                                            variant="h6">{Config.lang("Nguoi_phong_van")}</Typography>
                                    </AccordionSummary>
                                    <Divider variant="middle"/>
                                    <AccordionDetails classes={{
                                        root: classes.expansionDetailsRoot,
                                    }}>
                                        <div className={classes.expansionContent}>
                                            {this.renderInterviewer()}
                                        </div>
                                        <div className={classes.expansionAction}>
                                            <Typography className={classes.titleExpandDetails}
                                                        variant="subtitle1">
                                                {Config.lang("Trang_thai_phong_van")}
                                            </Typography>
                                            <ButtonGroup
                                                className={classes.buttonGroup}
                                                classes={{grouped: classes.btnGroup}}
                                                size="small"
                                                variant="text"
                                            >
                                                <Button
                                                    onClick={() => this.onUpdateInterviewStatus("SCHEDULED")}
                                                    className={[
                                                        classes.btnWarning,
                                                        classes.subText,
                                                        interviewStatusID === "SCHEDULED" ? classes.focusedBtnWarning : "",
                                                    ].join(" ")}
                                                >
                                                    {"Scheduled"}
                                                </Button>
                                                <Button
                                                    onClick={() => this.onUpdateInterviewStatus("STARTED")}
                                                    className={[
                                                        classes.btnSuccess,
                                                        classes.subText,
                                                        interviewStatusID === "STARTED" ? classes.focusedBtnSuccess : "",
                                                    ].join(" ")}
                                                >
                                                    {"Finished"}
                                                </Button>
                                                <Button
                                                    onClick={() => this.onUpdateInterviewStatus("CANCEL")}
                                                    className={[
                                                        classes.btnDanger,
                                                        classes.subText,
                                                        interviewStatusID === "CANCEL" ? classes.focusedBtnDanger : "",
                                                    ]}
                                                >
                                                    {"Cancel"}
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Accordion className={classes.boxShadowCard} defaultExpanded>
                                <AccordionSummary
                                    classes={{
                                        root: classes.expansionSummaryRoot,
                                        content: `${classes.expansionSummaryContent}  withSubTitle`,
                                    }}
                                    IconButtonProps={{size: "small"}}
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Typography variant="h6">
                                        {Config.lang("Bang_cau_hoi_phong_van")}
                                    </Typography>
                                    <Typography className={classes.subText} variant="subtitle2">
                                        {Config.lang("Cac_cau_hoi_phong_van_su_dung_cho_vi_tri_tuyen_dung")}
                                    </Typography>
                                </AccordionSummary>
                                <Divider variant="middle"/>
                                <AccordionDetails classes={{root: classes.expansionDetailsRoot}}>
                                    {this.renderQuestionContent()}
                                </AccordionDetails>
                            </Accordion>
                        </FormGroup>
                        <FormGroup>
                            <CandidateCVCard keyID={candidateID}/>
                        </FormGroup>
                        <FormGroup>
                            <Accordion className={classes.boxShadowCard} defaultExpanded>
                                <AccordionSummary
                                    classes={{
                                        root: classes.expansionSummaryRoot,
                                        content: `${classes.expansionSummaryContent}  withSubTitle`,
                                    }}
                                    IconButtonProps={{size: "small"}}
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Typography
                                        variant="h6">{Config.lang("Ghi_chu_tu_nguoi_tao_phong_van")}</Typography>
                                    <Typography className={classes.subText} variant="subtitle2">
                                        {Config.lang("Ghi_chu_rieng_tu_nguoi_tao_lich_phong_van")}
                                    </Typography>
                                </AccordionSummary>
                                <Divider variant="middle"/>
                                <AccordionDetails
                                    classes={{
                                        root: classes.expansionDetailsRoot,
                                    }}
                                >
                                    {this.renderNoteInterviewer()}
                                </AccordionDetails>
                            </Accordion>
                        </FormGroup>
                        <FormGroup>
                            <div ref={this.refSectionMail}>
                                <CandidateMailExchangeCard
                                    interviewFileID={InterviewFileID}
                                    interviewTitle={InterviewTitle}
                                    candidateEmail={_.get(getCandidateProfile, "CandidateEmail", "")}
                                    dataForm={getCandidateProfile}
                                    reloadDataMail={loadDataMail}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={4}>
                        <W25F2012 data={_.pick(this.params, ["CandidateID", "RecInfoID", "InterviewFileID"])}/>
                    </Col>
                </Row>
            </>
        );
    }

    componentDidMount = () => {
        this.loadForm();
    }
}

export default compose(
    connect(
        (state) => ({
            getCandidateProfile: state.general.getCandidateProfile,
        }),
        (dispatch) => ({
            w25f2033Actions: bindActionCreators(W25F2033Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles, {withTheme: true}),
)(W25F2033);
