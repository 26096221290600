import React, {Component} from 'react';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Icon,
    IconButton,
    Button,
    Tab,
    Tabs,
    Typography,
    withStyles
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {browserHistory} from "react-router";
import {Col, FormGroup, Row} from "react-bootstrap";
import CandidateProFileCard from "../Components/CandidateProFileCard";
import CandidateMailExchangeCard from "../Components/CandidateMailExchangeCard";
import _ from "lodash";
import ButtonGeneral from "../../../common/button/button-general";
import moment from "moment";
import UserImage from "../../../common/user/user-image";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as W25F2023Actions from "../../../../redux/W2X/W25F2023/W25F2023_actions";
import W25F2303 from "../W25F2303/W25F2303";
import W25F2022 from "../W25F2022/W25F2022";
import W25F2012 from "../W25F2012/W25F2012";
import Icons from "../../../common/icons";
import Api from "../../../../services/api";
import * as generalActions from "../../../../redux/general/general_actions";
import TransRecPopup from "../Components/TransRecPopup";


const styles = theme => ({
    containerCard: {
        padding: "15px 0",
        backgroundColor: "rgba(226,226,229,0.75)"
    },
    actionToolbar: {
        "& > div[class*='ActionToolbar-root-']": {
            padding: "0px !important",
            "& .MuiTab-root": {
                padding: "14px 12px"
            }
        }
    },
    tabsActionToolBar: {
        width: "100%",
        justifyContent: "space-between",
    },
    flexDRow: {
        display: "flex",
        alignItems: "center"
    },
    flexDColumn: {
        display: "flex",
        flexDirection: "column",
    },
    flexWrap: {
        display: "flex",
        flexWrap: "flex-wrap",
    },
    primaryText: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    infoText: {
        color: theme.palette.info.main,
        fontWeight: 400
    },
    iconWithLabel: {
        height: "auto",
        fontWeight: 400,
        "& .MuiIcon-root": {
            marginRight: 15,
            width: "1.3em",
            fontWeight: 400,
        }
    },
    boxShadow: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    cardHeaderCalendar: {
        backgroundColor: theme.palette.danger.main,
        padding: 10,
        "& .MuiCardHeader-title": {
            color: "#FFF",
            textAlign: "center",
            fontWeight: 600,
            fontSize: "1.6rem",
        },
    },
    cardContentCalendar: {
        "& .MuiTypography-root.MuiTypography-subtitle1": {
            fontWeight: 600,
            color: theme.palette.text.subtext,
        },
        "&:last-child": {
            paddingBottom: 14,
        },
    },
    interviewInfo: {
        width: "65%",
        [theme.breakpoints.up("xl")]: {
            width: "75%"
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%"
        }

    },
    calendarCard: {
        marginRight: 15,
        [theme.breakpoints.down("lg")]: {
            width: "15%"
        },
        [theme.breakpoints.up("md")]: {
            width: "20%"
        },
        [theme.breakpoints.down("sm")]: {
            width: "25%"
        }
    },
    loadingCard: {
        position: "absolute",
        width: '100%',
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        cursor: "pointer",
        top: 0
    },
    buttonAction: {
        display: "flex",
        alignItems: "center"
    },
    totalNumber: {
        width: 45,
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"
    },
    skeletonIconWithLabel: {
        marginBottom: 5
    }
})

class W25F2023 extends Component {
    constructor(props) {
        super(props);
        const {location} = this.props;
        this.state = {
            iPermission: 0,
            iPermissionW25F2010: 0,
            tabValue: "overview",
            loadingTab: false,
            recInfoIDUpdated: "",
            isReloadTabView: false,
            isReloadTabSchedule: true,
            loadingCuratorsList: false,
            showW25F2303Popup: false,
            showModalTransRecruitment: false,
            iSelected: _.get(location, "state.iSelected", null),
            mode: "add",
            dataPopup: {},
        }
        this.params = {
            FormID: "W25F2023",
            Language: Config.language || 84,
        };
        if (_.isUndefined(location.state)) {
            if (Config.getLocalStorage("DHR_W25F2023")) {
                location.state = JSON.parse(Config.getLocalStorage("DHR_W25F2023"));
                Config.removeLocalStorage("DHR_W25F2023");
            }
            else
                browserHistory.push(Config.getRootPath() + "W25F2010");
        };
    };

    loadPermissionW25F2010 = async () => {
        await this.props.generalActions.getPermission("W25F2010", (isPer) => {
            this.setState({iPermissionW25F2010: isPer});
        }, true);
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W25F2010", (isPer) => {
            this.setState({iPermission: isPer});
        }, true);
    };

    clsx = (...spread) => _.join(spread, " ");

    getInfoLocationStateCurrent = () => ({
        ...this.params,
        CandidateID: _.get(this.props.location, `state.dataListCandidate[${this.state.iSelected}].CandidateID`, _.get(this.props.location, "state.CandidateID", "")),
        RecInfoID: _.get(this.props.location, `state.dataListCandidate[${this.state.iSelected}].RecInfoID`, _.get(this.props.location, "state.RecInfoID", _.get(this.props.location, "state.voucher_id", ""))),
        InterviewFileID: _.get(this.props.location, `state.dataListCandidate[${this.state.iSelected}].InterviewFileID`, _.get(this.props.location, "state.InterviewFileID", "")),
    });

    loadTabView = (isCurator = false) => {
        if (!isCurator) this.setState({loadingTab: true, isReloadTabView: false});
        this.props.w25f2023Actions.loadTabView(this.getInfoLocationStateCurrent(), err => {
            if (!isCurator) this.setState({loadingTab: false});
            if (err) Config.popup.show("ERROR", err);
        })
    };

    loadInterviewSchedule = () => {
        this.setState({loadingTab: true, isReloadTabSchedule: false});
        this.props.w25f2023Actions.loadInterviewSchedule(this.getInfoLocationStateCurrent(), (err) => {
            this.setState({loadingTab: false})
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        })
    };

    onBackToScreen = () => {
        const {location} = this.props;
        const screenName = _.get(location, "state.ScreenName", "");
        if (screenName) {
            browserHistory.push({
                pathname: Config.getRootPath() + screenName,
                state: _.pick(this.getInfoLocationStateCurrent(), ["CandidateID", "RecInfoID", "InterviewFileID"])
            });
        }
        browserHistory.goBack();
    };

    onChooseACurator = (userID, action = "add") => {
        const {loadTabView} = this.props;
        const arrListMemberFollow = _.filter(_.get(loadTabView, "ListMemberFollow", "").split(","), id => action === "add" ? true : userID !== id);
        const ListMemberFollow = _.join(_.compact([...arrListMemberFollow, action === "add" ? userID : ""]), ",");
        if (action === "add") this.setState({loadingCuratorsList: true});
        this.props.w25f2023Actions.chooseACurator({
            ...this.getInfoLocationStateCurrent(),
            ListMemberFollow
        }, (err, data) => {
            if (action === "add") this.setState({loadingCuratorsList: false});
            if (err) Config.popup.show("ERROR", err);
            if (data) this.loadTabView(true);
        })
    };

    onChangeTab = (p, tabValue) => {
        const {iSelected, isReloadTabView, isReloadTabSchedule} = this.state;
        this.setState({tabValue});
        if (isReloadTabSchedule && tabValue === "schedules") {
            this.loadPermissionW25F2010()
            this.loadInterviewSchedule()
        }
        if (isReloadTabView && !_.isNull(iSelected)) {
            this.loadTabView()
        }
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W25F2015");
    };

    onCloseModal = (modal, isReload = false) => {
        this.setState({
            [modal]: false
        }, () => {
            if (isReload) {
                if (modal === "showModalTransRecruitment") {
                    this.loadTabView();
                } else {
                    this.loadInterviewSchedule()
                }
            }

        });
    };

    onOpenModal = (mode, data) => {
        const getInfoCandidate = this.getInfoLocationStateCurrent();
        this.setState({
            showW25F2303Popup: true,
            mode,
            dataPopup: {...getInfoCandidate,...data}
        });
    };

    onDeleteInterviewSchedule = (params) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_lich_phong_van_nay"), async () => {
            const res = await Api.delete('/w25f2033/delete-interview-file', params);
            const resMessageErr = _.get(res, "message", _.get(res, "data.Message", false));
            if (resMessageErr) {
                Config.popup.show("ERROR", resMessageErr);
                return false;
            }
            Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
            this.loadInterviewSchedule()
        })
    }

    onNextCandidate = () => {
        const {iSelected, tabValue} = this.state;
        let isReloadTabView = false;
        let isReloadTabSchedule = true;
        if (tabValue === "schedules") {
            isReloadTabView = true;
            isReloadTabSchedule = false
        }
        this.setState({
            iSelected: iSelected + 1,
            isReloadTabView,
            isReloadTabSchedule
        })
    };

    onPrevCandidate = () => {
        const {iSelected, tabValue} = this.state;
        let isReloadTabView = false;
        let isReloadTabSchedule = true;
        if (tabValue === "schedules") {
            isReloadTabView = true;
            isReloadTabSchedule = false;
        }
        this.setState({
            iSelected: iSelected - 1,
            isReloadTabView,
            isReloadTabSchedule,
        })
    };

    formatDate = (date, format = "DD/MM/YYYY") => {
        const fmDate = moment.utc(date);
        let resDate = "";
        if (!fmDate.isValid()) return resDate;
        resDate = fmDate.format(format);
        if (format === "dddd") {
            switch (_.upperCase(resDate)) {
                case "MONDAY":
                    resDate = Config.lang("Thu_hai");
                    break;
                case "TUESDAY":
                    resDate = Config.lang("Thu_ba");
                    break;
                case "WEDNESDAY":
                    resDate = Config.lang("Thu_tu");
                    break;
                case "THURSDAY":
                    resDate = Config.lang("Thu_nam");
                    break;
                case "FRIDAY":
                    resDate = Config.lang("Thu_sau");
                    break;
                case "SATURDAY":
                    resDate = Config.lang("Thu_bay");
                    break;
                case "SUNDAY":
                    resDate = Config.lang("Chu_nhat");
                    break;
                default:
                    break;
            }
        }
        return resDate;
    };

    renderUserImageHover = (user) => {
        const arrUserInfo = _.filter([
            {
                label: Config.lang('Nhan_vien'),
                value: _.join(_.compact(
                    [
                        _.trim(_.get(user, "EmployeeID", "")),
                        _.trim(_.get(user, "EmployeeName", ""))
                    ]), " - ")
            },
            {
                label: Config.lang('Phong_ban'),
                value: _.trim(_.get(user, "DepartmentName", ""))
            },
            {
                label: Config.lang('Du_an'),
                value: _.trim(_.get(user, "ProjectName", ""))
            },
            {
                label: Config.lang('Chuc_vu'),
                value: _.trim(_.get(user, "DutyName", ""))
            },
            {
                label: Config.lang('Ngay_vao_lam'),
                value: _.trim(_.get(user, "dateJoined", ""))
            }
        ], item => Boolean(item.value))
        return _.map(arrUserInfo, (item, i) => <span key={i}>{item.label}: {item.value}<br/></span>)
    };

    renderSkeletonImage = (size, styles = {}, n = 1) => {
        return _.map(Array(n), (_, i) => <Skeleton key={i} style={{...styles}}
                                                   animation={(i + 1) === n && n > 1 ? "pulse" : "wave"}
                                                   variant="circle"
                                                   width={size} height={size}/>)
    };

    renderInfoSkeletonIconWithLabel = (nLine = 3) => {
        const {classes} = this.props;
        return (_.map(Array(nLine), (q, i) => {
                const offsetWSkLabel = (i + 1) === nLine ? 10 : 0;
                return (
                    <div key={i}
                         className={_.join([classes.flexDRow, classes.iconWithLabel, classes.skeletonIconWithLabel], " ")}>
                        {this.renderSkeletonImage(25, {marginRight: 15})}
                        <Typography style={{width: "100%"}} component="div">
                            <Skeleton width={(45 - offsetWSkLabel) + "%"}
                                      animation={(i + 1) === nLine && nLine > 1 ? "pulse" : "wave"}/>
                        </Typography>
                    </div>
                )
            }
        ))
    };

    renderInterviewScheduleCard = () => {
        const {classes, loadInterviewSchedule, loadInfoCandidate} = this.props;
        const isViewOnly = this.props?.location?.state?.isViewOnly || false;
        const {loadingTab, iPermission, iPermissionW25F2010} = this.state;
        const dataInterviewSchedule = _.isArray(loadInterviewSchedule) ? loadInterviewSchedule : [loadInterviewSchedule];
        const hasDataInterviewSchedule = Boolean(_.get(loadInterviewSchedule, "length", !_.isEmpty(loadInterviewSchedule)))
        const stageID = _.upperCase(_.get(loadInfoCandidate, "[0]StageID", ""))
        const isRejected = stageID === "REJECTED";
        const isHired= stageID === "HIRED";

        const isMoved = !!_.get(loadInfoCandidate,"[0]IsMoved", _.get(loadInfoCandidate,"IsMoved", 0));
        const isEmployee = !!_.get(loadInfoCandidate,"[0]IsEmployee", _.get(loadInfoCandidate,"IsEmployee", 0));
        const ApproveLevel = loadInfoCandidate[0]?.ApproveLevel || 0;
        const ApprovalStatus = loadInfoCandidate[0]?.ApprovalStatus || 0;
        return (
            <FormGroup>
                <Card variant="outlined" className={classes.boxShadowCard}>
                    <CardContent>
                        <FormGroup>
                            <ButtonGeneral
                                disabled={isMoved || isRejected || isHired || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                                name={Config.lang("Them_lich_phong_van")}
                                typeButton={"add"}
                                style={{textTransform: "uppercase"}}
                                size={"large"}
                                onClick={() => this.onOpenModal("add")}
                            />
                        </FormGroup>
                        {hasDataInterviewSchedule ? _.map(dataInterviewSchedule, (item, i) => {
                            const interviewDateTime = _.get(item, "InterviewDateTime", "");
                            const recInfoTitle = _.get(item, "RecInfoTitle", "")
                            const interviewerList = _.chain(_.get(item, "InterviewerList", ""))
                                .split(',')
                                .map(UserID => Config.getUser({UserID}))
                                .compact()
                                .value();
                            const interviewPlace = _.get(item, "InterviewPlace", "");
                            const interviewDate = this.formatDate(interviewDateTime);
                            const interviewDay = this.formatDate(interviewDateTime, "dddd");
                            const interviewTime = this.formatDate(interviewDateTime, "LT");
                            let interviewName = ""
                            const interviewType = _.get(item, "InterviewType", "");
                            switch (_.toUpper(interviewType)) {
                                case "ONSITE":
                                    interviewName = Config.lang("Phong_van_tai_van_phong")
                                    break;
                                case "VIDEO":
                                    interviewName = Config.lang("Phong_van_online")
                                    break;
                                case "PHONE":
                                    interviewName = Config.lang("Phong_van_qua_dien_thoai")
                                    break;
                                default:
                                    break
                            }
                            const arrInterviewInfo = _.filter([
                                {
                                    value: recInfoTitle,
                                    iconName: "far fa-suitcase",
                                },
                                {
                                    value: interviewName,
                                    iconName: "fas fa-comments-alt",
                                },
                                {
                                    value: interviewPlace,
                                    iconName: "far fa-map-marker-alt",
                                },
                            ], item => Boolean(item.value));
                            // const {InterviewFileID} = this.getInfoLocationStateCurrent();
                            // item.InterviewFileID = InterviewFileID;
                            const isAction = !!_.toNumber(_.get(item, "IsAction"));
                            return (
                                <FormGroup key={i}>
                                    <FormGroup>
                                        <Divider/>
                                    </FormGroup>
                                    <div className={classes.flexDRow}>
                                        <div className={classes.calendarCard}>
                                            <Card className={classes.boxShadow} variant="outlined">
                                                <CardHeader className={classes.cardHeaderCalendar}
                                                            title={!loadingTab ? interviewTime : interviewTime ?
                                                                <Skeleton animation="wave"/> : ""}/>
                                                <CardContent className={classes.cardContentCalendar}>
                                                    <Typography component="div" align="center">
                                                        <Typography align="center" variant="subtitle1">
                                                            {!loadingTab ? interviewDate : interviewDate ?
                                                                <Skeleton animation="wave"/> : ""}
                                                        </Typography>
                                                        <Typography align="center" variant="subtitle1">
                                                            {!loadingTab ? (interviewDay ? `(${interviewDay})` : "") : interviewDay ?
                                                                <Skeleton animation="wave"/> : ""}
                                                        </Typography>
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                        <div className={classes.interviewInfo}>
                                            {!loadingTab ? _.map(arrInterviewInfo, (item, i) => (
                                                <div key={i}
                                                     className={_.join([classes.flexDRow, classes.iconWithLabel], " ")}>
                                                    <Icon
                                                        fontSize="small"
                                                        className={item.iconName}
                                                    />
                                                    <Typography
                                                        className={item.iconName === "far fa-suitcase" ? classes.infoText : ""}
                                                        variant="subtitle1">
                                                        {item.value}
                                                    </Typography>
                                                </div>
                                            )) : this.renderInfoSkeletonIconWithLabel(arrInterviewInfo.length)}
                                            <div className={classes.flexWrap}>
                                                {!loadingTab ? _.map(interviewerList, (dataUser, i) => (<UserImage
                                                    keyExpr={"UserID"}
                                                    valueExpr={"UserID"}
                                                    key={i}
                                                    data={dataUser}
                                                    width={30}
                                                    height={30}
                                                    renderItemHover={(user) => this.renderUserImageHover(user)}
                                                />)) : this.renderSkeletonImage(30, {marginRight: 5}, interviewerList.length)
                                                }
                                            </div>
                                        </div>
                                        {isAction && <div className={classes.buttonAction}>
                                            <IconButton
                                                onClick={() => this.onOpenModal("edit", item)}
                                                disabled={iPermission < 2 || iPermissionW25F2010 < 2 || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                                                style={{padding: 0, width: 30, height: 30, marginRight: 3}}
                                            >
                                                <Icons name={"edit"}/>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => this.onDeleteInterviewSchedule(item)}
                                                disabled={iPermission < 3 || iPermissionW25F2010 < 3 || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                                                style={{padding: 0, width: 30, height: 30, marginRight: 3}}
                                            >
                                                <Icons name={"delete"}/>
                                            </IconButton>
                                        </div>}
                                    </div>
                                </FormGroup>
                            )
                        }) : ""}
                    </CardContent>
                </Card>
            </FormGroup>)
    };

    renderOverviewCard = () => {
        const {loadingTab, loadingCuratorsList} = this.state;
        const isViewOnly = this.props?.location?.state?.isViewOnly || false;
        const {loadTabView} = this.props;
        const {InterviewFileID} = this.getInfoLocationStateCurrent();
        return (
            <>
                <FormGroup>
                    <CandidateProFileCard
                        isLoadingCuratorList={loadingCuratorsList}
                        isLoading={loadingTab}
                        onChooseACurator={this.onChooseACurator}
                        mode={"overview"}
                        data={loadTabView}
                        isViewOnly={isViewOnly}
                    />
                </FormGroup>
                <FormGroup>
                    <CandidateMailExchangeCard dataForm={{ ...loadTabView, InterviewFileID }} isViewOnly={isViewOnly} />
                </FormGroup>
            </>
        );
    };

    renderCard = () => {
        const {loadTabView} = this.props;
        const isViewOnly = this.props?.location?.state?.isViewOnly || false;
        const {tabValue, loadingTab} = this.state;
        let componentCard = ""
        switch (tabValue) {
            case "overview":
                componentCard = this.renderOverviewCard();
                break;
            case "apply":
                componentCard = <W25F2022
                    isLoading={{candidateProfileCard: loadingTab}}
                    mode={"tab"}
                    data={loadTabView}
                    isViewOnly={isViewOnly}
                />
                break;
            case "schedules":
                componentCard = this.renderInterviewScheduleCard();
                break;
            default:
                break;
        }
        return componentCard;
    };

    render() {
        const {classes, data, loadTabView, location, loadInfoCandidate} = this.props;
        const isViewOnly = this.props?.location?.state?.isViewOnly || false;
        const {
            tabValue,
            showW25F2303Popup,
            iSelected,
            loadingTab,
            mode,
            dataPopup,
            showModalTransRecruitment,
            recInfoIDUpdated
        } = this.state;
        const candidateName = _.get(data, "CandidateName", _.get(loadTabView, "CandidateName", ""));
        const arrTabItem = [
            {
                value: "overview",
                label: Config.lang("Tong_quan")
            },
            {
                value: "apply",
                label: Config.lang("Don_ung_tuyen")
            },
            {
                value: "schedules",
                label: Config.lang("Phong_van")
            }
        ];
        const totalCandidate = _.get(location, "state.dataListCandidate", []).length;
        const candidateOnTotal = `${iSelected + 1} / ${totalCandidate}`;
        const isMoved = !!_.get(loadInfoCandidate,"[0]IsMoved", _.get(loadInfoCandidate,"IsMoved", 0));
        const isEmployee = !!_.get(loadInfoCandidate,"[0]IsEmployee", _.get(loadInfoCandidate,"IsEmployee", 0));
        const isDisableTransRec = isMoved || _.get(loadInfoCandidate, "[0]StageID", "") === "HIRED";
        const getInfoCandidate = this.getInfoLocationStateCurrent();
        const ApproveLevel = loadInfoCandidate[0]?.ApproveLevel || 0;
        const ApprovalStatus = loadInfoCandidate[0]?.ApprovalStatus || 0;
        return (
            <>
                {showModalTransRecruitment ?
                    <TransRecPopup
                        onUpdateTransRec={(recInfoID) => this.setState({recInfoIDUpdated: recInfoID})}
                        paramsProps={{...getInfoCandidate, FormID: "W25F2023"}}
                        isOpen={showModalTransRecruitment}
                        onClose={(isReload) => this.onCloseModal("showModalTransRecruitment", false)}/> :
                    ""}
                <ActionToolbar className={classes.actionToolbar} title={candidateName} onBack={this.onBackToScreen}>
                    <div className={this.clsx(classes.flexDRow, classes.tabsActionToolBar)}>
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            value={tabValue}
                            onChange={this.onChangeTab}
                        >
                            {_.map(arrTabItem, (item, i) => <Tab disabled={loadingTab} key={i} {...item}/>)}
                        </Tabs>
                        <div className={classes.flexDRow}>
                            <Button
                                disabled={isDisableTransRec || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                                onClick={() => this.setState({showModalTransRecruitment: true})}
                                style={{marginRight: 15}} variant="contained" color="primary"
                                disableElevation>
                                {Config.lang("Chuyen_tin_tuyen_dung")}
                            </Button>
                            {totalCandidate ? <div className={classes.buttonAction}>
                                <IconButton
                                    disabled={iSelected + 1 === 1 || loadingTab || (ApproveLevel > 1 && ApprovalStatus === 0)}
                                    size="small"
                                    onClick={this.onPrevCandidate}
                                    color={"primary"}
                                >
                                    <Icon className="fas fa-chevron-circle-left"/>
                                </IconButton>
                                <div className={classes.totalNumber}>
                                    <span> {candidateOnTotal} </span>
                                </div>
                                <IconButton
                                    disabled={iSelected + 1 === totalCandidate || loadingTab || (ApproveLevel > 1 && ApprovalStatus === 0)}
                                    size="small"
                                    onClick={this.onNextCandidate}
                                    color={"primary"}
                                >
                                    <Icon className="fas fa-chevron-circle-right"/>
                                </IconButton>
                            </div> : ""}
                        </div>
                    </div>
                </ActionToolbar>
                {showW25F2303Popup ? <W25F2303
                    mode={mode}
                    data={dataPopup}
                    open={showW25F2303Popup}
                    onClose={(isReload) => this.onCloseModal("showW25F2303Popup", isReload)}
                    FormID="W25F2023"
                /> : ""}
                <Row className={classes.containerCard}>
                    <Col lg={8} xs={12}>
                        {this.renderCard()}
                    </Col>
                    <Col lg={4} xs={12}>
                        <W25F2012
                            isViewOnly={isViewOnly}
                            fullCandidateInfo={loadTabView}
                            recInfoIDChange={recInfoIDUpdated}
                            data={_.pick(this.getInfoLocationStateCurrent(), ["CandidateID", "RecInfoID", "InterviewFileID"])}/>
                    </Col>
                </Row>
            </>
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.get(this.props.location, `state.dataListCandidate[${this.state.iSelected}].CandidateID`, _.get(this.props.location, "state.CandidateID", false)) !==
            _.get(this.props.location, `state.dataListCandidate[${prevState.iSelected}].CandidateID`, _.get(prevProps.location, "state.CandidateID"), false)) {
            if (this.state.tabValue === "schedules") {
                this.loadInterviewSchedule()
            } else {
                this.loadTabView()
            }
        }
    };


    componentDidMount() {
        this.loadTabView();
        this.loadPermission()
    };
}

export default compose(connect(
    state => ({
        loadTabView: state.W25F2023.loadTabView,
        loadInterviewSchedule: state.W25F2023.loadInterviewSchedule,
        loadInfoCandidate: state.W25F2012.loadInfoCandidate,
        chooseACurator: state.W25F2023.chooseACurator,
    }),
    dispatch => ({
        w25f2023Actions: bindActionCreators(W25F2023Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch)
    })
), withStyles(styles, {withTheme: true}))(W25F2023);
