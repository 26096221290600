/**
 * @copyright 2021 @ DigiNet
 * @author DINHTHIEN
 * @create 29/1/2021
 * @Example
 */
import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {FormGroup, Row} from "react-bootstrap";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import TooltipM from "@material-ui/core/Tooltip";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import DateBoxPicker from "../../../common/form-material/date-box";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import _ from "lodash";
import {Chart, Title, ValueAxis, LoadingIndicator} from 'devextreme-react/chart';
import PieChart, {Legend, Series, Tooltip, Connector, Label} from 'devextreme-react/pie-chart';
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Api from "../../../../services/api";
import Skeleton from '@material-ui/lab/Skeleton';
import UserImage from "../../../common/user/user-image";
import moment from "moment";
import ButtonGeneral from "../../../common/button/button-general";
import * as generalActions from "../../../../redux/general/general_actions";
import Icons from "../../../common/icons";
import html2canvas from "html2canvas";

const colorBarChart = {total: "#1164F4", new: "#0095FF", offered: "#FFAA00", hired: "#9914C7"};
const colorPieChart = ["#8BC3FF", "#469FFF", "#1164F4"]
const styles = (theme) => {
    return ({
        actionToolbarDate: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            "& > label": {
                width: "13%",
                margin: "2px 12px",
                fontWeight: 400,
                fontSize: "0.85rem"
            },
            "& > .MuiFormControl-root.MuiFormControl-fullWidth": {
                maxWidth: 240
            }
        },
        actionToolbar: {
            marginLeft: -15,
            marginRight: -15,
            "&.action-toolbar": {
                width: "auto",
                padding: "0px 30px"
            }
        },
        cardHeaderRoot: {
            alignItems: "flex-start"
        },
        cardHeaderAction: {
            marginRight: 0
        },
        rowContainer: {
            paddingTop: 30,
            backgroundColor: "#F5F6F6",
            paddingLeft: 20,
            paddingRight: 20
        },
        contentContainer: {
            display: "flex",
        },
        contentContainerCard: {
            marginLeft: 10,
            marginRight: 10
        },
        contentContainerCardFTitleHeader: {
            display: "flex",
            justifyContent: "space-between"
        },
        contentContainerCardFTitleHeaderRight: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
        },
        colorTotal: {
            color: "#1164F4"
        },
        colorNew: {
            color: theme.palette.info.main
        },
        colorInterviewed: {
            color: theme.palette.success.main
        },
        colorOffered: {
            color: theme.palette.warning.main
        },
        colorHired: {
            color: "#9914C7"
        },
        colorRejected: {
            color: theme.palette.danger.main
        },
        listItemIconRoot: {
            minWidth: 22
        }
    })
}

const W25F4000 = ({classes, generalActions}) => {
    const [dataRecruitmentInfo, setDataRecruitmentInfo] = useState([]);
    const [dataRecruitmentInfoByDay, setDataRecruitmentInfoByDay] = useState({});
    const [dataRecruitmentInfoByWeek, setDataRecruitmentInfoByWeek] = useState({});
    const [dataRecruitmentGrid, setDataRecruitmentGrid] = useState({});
    const [dataRecruitmentTotal, setDataRecruitmentTotal] = useState({});

    const [loadingRecInfo, setLoadingRecInfo] = useState(false);
    const [loadingRecInfoByDay, setLoadingRecInfoByDay] = useState(false);
    const [loadingRecInfoByWeek, setLoadingRecInfoByWeek] = useState(false);
    const [loadingRecGrid, setLoadingRecGrid] = useState(false);
    const [loadingRecTotalGrid, setLoadingRecTotalGrid] = useState(false);
    const [loadingPdf, setLoadingPDF] = useState(false);

    const [dateFrom, setDateFrom] = useState(moment().clone().startOf('month').format("YYYY-MM-DD"));
    const [dateTo, setDateTo] = useState(moment().clone().endOf('month').format("YYYY-MM-DD"));
    const [iPermission, setIPermission] = useState(0);

    const refGridRec = useRef(null);

    useEffect(() => {
        let timerIDRecInfo = null;
        let timerIDRecInfoByDay = null;
        let timerIDRecInfoByWeek = null;
        let timerIDRecGrid = null;
        let timerIDRecTotalGrid = null;
        (async (DateFrom, DateTo) => {
            let params = {Language: Config.language || "84"};
            try {
                setLoading(0);

                timerIDRecInfo = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 1,
                    DateFrom,
                    DateTo
                }).then(handleResponse).then(resData => {
                    if (!resData) return -1;
                    setDataRecruitmentInfo(_.map(resData, (item, i) => ({
                        title: _.get(item, "TypeName", ""),
                        isRecInfo: true,
                        description: _.get(item, "Description", ""),
                        action: {
                            title: i === 1 ? Config.lang("DHR_Da_dien_ra") : Config.lang("DHR_Ung_vien"),
                            number: _.get(item, "Number", 0),
                        },
                        dataContent: _.get(item, "detail", [])
                    })));
                    return 1
                }).then(setLoading);

                timerIDRecInfoByDay = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 2,
                    DateFrom,
                    DateTo
                }).then(handleResponse).then(resData => {
                    if (!resData) return -2;
                    setDataRecruitmentInfoByDay({
                        title: Config.lang("DHR_Tong_hop_ung_vien_moi_theo_ngay"),
                        dataContent: {
                            dataSources: _.map(resData, item => ({
                                ...item,
                                DateRec: moment.utc(_.get(item, "DateRec", "")).format("DD/MM/YYYY")
                            })),
                            dataSeries: [{
                                valueField: "NumCanNumber",
                                argumentField: "DateRec",
                                name: "Total",
                                type: "bar",
                                color: colorBarChart.total
                            }]
                        }
                    });
                    return 2;
                }).then(setLoading);

                timerIDRecInfoByWeek = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 3,
                    DateFrom,
                    DateTo
                }).then(handleResponse).then(resData => {
                    if (!resData) return -3;
                    let tempPeriod = _.get(resData, "[0]Period", "");
                    let tempIndex = 0;
                    setDataRecruitmentInfoByWeek({
                        title: Config.lang("DHR_Tong_hop_ung_vien_moi_theo_tuan"),
                        dataContent: {
                            dataSources: _.reduce(resData, (acc, currItem) => {
                                if (currItem.Period !== tempPeriod) {
                                    tempIndex++;
                                    tempPeriod = currItem.Period;
                                }
                                acc[tempIndex] = {
                                    ...acc[tempIndex],
                                    Period: currItem.Period,
                                    [`NumCan${currItem.TypeID}`]: currItem.NumCan
                                }
                                return acc;
                            }, []),
                            dataSeries: _.chain(resData).map(item => item.TypeID).uniq().map((typeID) => ({
                                valueField: `NumCan${typeID}`,
                                argumentField: "Period",
                                name: typeID,
                                type: "bar",
                                color: colorBarChart[_.toLower(typeID)]
                            })).value()
                        }
                    });
                    return 3;
                }).then(setLoading);

                timerIDRecGrid = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 4,
                    DateFrom,
                    DateTo
                }).then(handleResponse).then(resData => {
                    if (!resData) return -4;
                    setDataRecruitmentGrid({
                        title: Config.lang("DHR_Tin_tuyen_dung"),
                        dataContent: resData
                    });
                    return 4;
                }).then(setLoading);

                timerIDRecTotalGrid = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 5,
                    DateFrom,
                    DateTo
                }).then(handleResponse).then(resData => {
                    if (!resData) return -5;
                    setDataRecruitmentTotal({
                        title: Config.lang("DHR_Tong_hop_ung_vien_theo_nguon_tuyen"),
                        dataContent: {
                            dataSources: resData,
                            dataSeries: _.map(resData, (item, i) => ({color: colorPieChart[i]}))
                        }
                    });
                    return 5
                }).then(setLoading);
            } catch (e) {
                console.log(e);
            }
        })(dateFrom, dateTo);
        return () => {
            if (timerIDRecInfo) clearTimeout(timerIDRecInfo);
            if (timerIDRecInfoByDay) clearTimeout(timerIDRecInfoByDay);
            if (timerIDRecInfoByWeek) clearTimeout(timerIDRecInfoByWeek);
            if (timerIDRecGrid) clearTimeout(timerIDRecGrid);
            if (timerIDRecTotalGrid) clearTimeout(timerIDRecTotalGrid);
        }
    }, [dateFrom, dateTo]);

    useEffect(() => {
        (async () => {
            await generalActions.getPermission("W25F4000", (isPer) => {
                setIPermission(isPer)
            }, true);
        })()
    }, [generalActions]);

    const onPointHoverChanged = ({target: point}) => {
        point.showTooltip();
    };

    const onExportExcel = async () => {
        const dataSource = refGridRec.current.dataGrid.instance.option("dataSource");
        if (dataSource) {
            await refGridRec.current.dataGrid.instance.exportToExcel();
            Config.notify.show("success", Config.lang("DHR_Xuat_du_lieu_thanh_cong"), 3000)
        } else {
            Config.popup.show('INFO', Config.lang("DHR_No_data"))
        }
    };

    const onExportPDF = () => {
        const input = document.getElementById('containerToPrint');
        html2canvas(input, {
            scale: '0.5',
            allowTaint: true,
            useCORS: true,
            scrollX: 0,
            scrollY: -window.scrollY
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            if (canvas && imgData) {
                try {
                    setLoadingPDF(true);
                    const myWindow = window.open('', Config.lang("DHR_Danh_gia_nhan_vien"));
                    myWindow.document.write('<body onload="window.print();window.close();"><img src="' + imgData + '" /></body>');
                    myWindow.document.close();
                    myWindow.focus();
                    setLoadingPDF(false);
                } catch (e) {
                    setLoadingPDF(false);
                    Config.popup.show("INFO", Config.lang("DHR_Co_loi_xay_ra_trong_qua_trinh_xu_ly"));
                    return false;
                }
            } else {
                Config.popup.show("INFO", Config.lang("DHR_Dang_chuan_bi_du_lieu_Xin_vui_long_cho"));
                return false;
            }
        });
    }

    const handleResponse = (res) => {
        if (_.isEmpty(res)) return false;
        const resData = _.get(res, "data", {});
        const resMessageErr = _.get(res, "message", _.get(resData, "Message", ""));
        if (resMessageErr) {
            Config.popup.show("ERROR", resMessageErr);
            return false;
        }
        return _.isEmpty(resData) ? false : resData
    }

    const setLoading = (mode) => {
        switch (mode) {
            case 0:
                setLoadingRecInfo(true);
                setLoadingRecInfoByDay(true);
                setLoadingRecInfoByWeek(true);
                setLoadingRecGrid(true);
                setLoadingRecTotalGrid(true);
                return ""
            case 1:
                return setTimeout(() => setLoadingRecInfo(false), 300);
            case 2:
                return setTimeout(() => setLoadingRecInfoByDay(false), 300);
            case 3:
                return setTimeout(() => setLoadingRecInfoByWeek(false), 300);
            case 4:
                return setTimeout(() => setLoadingRecGrid(false), 300);
            case 5:
                return setTimeout(() => setLoadingRecTotalGrid(false), 300);
            case -1:
                setLoadingRecInfo(false);
                return false;
            case -2:
                setLoadingRecInfoByDay(false);
                return false;
            case -3:
                setLoadingRecInfoByWeek(false);
                return false
            case -4:
                setLoadingRecGrid(false)
                return false;
            case -5:
                setLoadingRecTotalGrid(false)
                return false;
            default:
                return ""
        }
    }

    const handleChangeDateBox = (e, type) => {
        const value = _.get(e, "value", "");
        switch (type) {
            case "dateFrom":
                setDateFrom(value);
                break;
            case "dateTo":
                setDateTo(value);
                break;
            default:
                break;
        }
    };

    const renderTitle = (title = "", description = false) => {
        return loadingRecInfo ? <Skeleton width={120} variant={"text"}/> :
            <div style={{display: "flex", alignItems: "center"}}>
                <label style={{fontSize: "1rem", fontWeight: 600, marginBottom: 0}}>{title}</label>
                {description ?
                    <TooltipM title={description}>
                        <Icon style={{marginLeft: 4}} fontSize={"small"} className="fas fa-info-circle"/>
                    </TooltipM> : ""}
            </div>
    };

    const renderCard = (cardWidth = "0%",
                        dataCard = [],
                        renderContent = () => "",
                        renderAction = () => "") => {
        return _.map(_.isPlainObject(dataCard) ? [dataCard] : dataCard, (item, i) => (
            <Card key={i}
                  style={{width: cardWidth}}
                  className={classes.contentContainerCard}>
                <CardHeader
                    title={renderTitle(item.title, _.get(item, "description", false))}
                    classes={{
                        root: classes.cardHeaderRoot,
                        action: classes.cardHeaderAction,
                        title: _.get(item, "isRecInfo", false) ? classes[`color${item.title}`] : ""
                    }}
                    action={renderAction(item, i)}
                />
                <Divider variant={"middle"}/>
                <CardContent>{renderContent(_.get(item, "dataContent", []))}</CardContent>
            </Card>))
    };

    const renderInfoContent = (data = []) => {
        return (
            <List disablePadding dense>
                {_.map(data, (item, i) => (
                    <ListItem key={i} disableGutters>
                        <ListItemIcon classes={{root: classes.listItemIconRoot}}>
                            {loadingRecInfo ? <Skeleton variant={"circle"} width={15} height={15}/> :
                                <FiberManualRecordIcon fontSize={"small"}/>}
                        </ListItemIcon>
                        <ListItemText
                            primary={loadingRecInfo ? <Skeleton variant={"text"} width={"60%"}/> : _.join([
                                _.get(item, "Value", ""),
                                _.get(item, "ValueName", "")
                            ], " ")}/>
                    </ListItem>
                ))}
            </List>
        )
    };

    const renderInfoAction = ({title, action}) => {
        return <div className={classes.contentContainerCardFTitleHeaderRight}>
            {loadingRecInfo ? <Skeleton variant="rect" width={30} height={30}/> :
                <label className={classes[`color${title}`]} style={{
                    textAlign: "end",
                    fontSize: "1.5rem",
                    marginBottom: 0,
                }}>{_.get(action, "number", 0)}</label>}
            {loadingRecInfo ? <Skeleton variant="text" width={60}/> : <label style={{
                fontSize: "0.8rem",
                fontWeight: 400,
                color: "#7F828E"
            }}>{_.get(action, "title", "")}</label>}
        </div>
    };

    const renderBarChartContent = ({dataSources = [], dataSeries = []}) => {
        return (
            <>
                {loadingRecInfoByDay || loadingRecInfoByWeek ?
                    <Skeleton variant="rect" width={"100%"} height={400}/> :
                    <Chart id="chart1" dataSource={dataSources}>
                        {_.map(dataSeries, (item, i) => <Series key={i} {...item} />)}
                        <Legend verticalAlignment="bottom" horizontalAlignment="center"/>
                        <ValueAxis>
                            <Title text={Config.lang("DHR_So_luong_ung_vien")}/>
                        </ValueAxis>
                        <LoadingIndicator enabled={true}/>
                        <Tooltip enabled={true}/>
                    </Chart>}
            </>
        )
    };

    const renderGridContent = (dataGrid) => {
        return <GridContainer
            pagerFullScreen={false}
            ref={refGridRec}
            style={{border: "none"}}
            dataSource={dataGrid || []}
            keyExpr={"RecInfoCode"}
            showBorders={false}
            loading={loadingRecGrid}
            rowAlternationEnabled={false}
            typePaging={"normal"}
            hoverStateEnabled={true}
            gridProps={{
                onExporting: (e) => {
                    e.fileName = "Tin_tuyen_dung_" + new Date().getTime();
                }
            }}
        >

            <Column
                caption={Config.lang("DHR_Ma")}
                dataField={"RecInfoCode"}
                alignment={"left"}
            />
            <Column
                caption={Config.lang("DHR_Thong_tin")}
                dataField={"RecInfoTitle"}
                alignment={"left"}
            />
            <Column
                caption={Config.lang("DHR_Nguoi_tao")}
                dataField={"CreateUserID"}
                cellRender={({data}) => <UserImage
                    valueExpr={'UserID'}
                    keyExpr={"CreateUserID"}
                    data={data}
                    width={32} height={32}/>}
                alignment={"left"}
            />
            <Column
                caption={Config.lang("DHR_Quan_ly")}
                dataField={"ListMember"}
                cellRender={({data}) => <div style={{display: "flex"}}>{_.map(
                    _.split(_.get(data, "ListMember", ""), ";"), (UserID, i) =>
                        <div key={i}>
                            <UserImage
                                keyExpr={"UserID"}
                                data={{UserID}}
                                width={32}
                                height={32}/>
                        </div>)}
                </div>}
                alignment={"left"}
            />
            <Column
                caption={Config.lang("DHR_Ngay_het_han")}
                dataField={"Deadline"}
                alignment={"center"}
                dataType={"date"}
                format={"dd/MM/yyyy"}
                cellRender={({data}) => moment.utc(_.get(data, "Deadline", "")).format("DD/MM/YYYY")}
            />
            <Column
                caption={Config.lang("Interviewed")}
                dataField={"Interviewed"}
                cellRender={({data}) => <label
                    className={classes.colorInterviewed}>{_.get(data, "Interviewed", 0)}</label>}
                alignment={"center"}
            />
            <Column
                caption={Config.lang("Offered")}
                dataField={"Offered"}
                cellRender={({data}) => <label className={classes.colorOffered}>{_.get(data, "Offered", 0)}</label>}
                alignment={"center"}
            />
            <Column
                caption={Config.lang("Hired")}
                dataField={"Hired"}
                cellRender={({data}) => <label className={classes.colorHired}>{_.get(data, "Hired", 0)}</label>}
                alignment={"center"}
            />
            <Column
                caption={Config.lang("Rejected")}
                cellRender={({data}) => <label
                    className={classes.colorRejected}>{_.get(data, "Rejected", 0)}</label>}
                alignment={"center"}
            />
        </GridContainer>
    };

    const renderGridTotalContent = ({dataSources}) => {
        return <GridContainer
            pagerFullScreen={false}
            style={{border: "none"}}
            dataSource={dataSources || []}
            showBorders={false}
            loading={loadingRecTotalGrid}
            rowAlternationEnabled={false}
            typePaging={"normal"}
            hoverStateEnabled={true}
        >
            <Column
                caption={Config.lang("DHR_Nguon")}
                dataField={"RecSourceName"}
                alignment={"left"}
            />
            <Column
                caption={Config.lang("Interviewed")}
                cellRender={({data}) => <label
                    className={classes.colorInterviewed}>{_.get(data, "Interviewed", 0)}</label>}
                alignment={"center"}
            />
            <Column
                caption={Config.lang("Hired")}
                cellRender={({data}) => <label className={classes.colorHired}>{_.get(data, "Hired", 0)}</label>}
                alignment={"center"}
            />
            <Column
                caption={Config.lang("Offered")}
                cellRender={({data}) => <label className={classes.colorOffered}>{_.get(data, "Offered", 0)}</label>}
                alignment={"center"}
            />
        </GridContainer>
    }

    const renderGridAction = () => {
        return <ButtonGeneral
            name={Config.lang("DHR_Tai_bao_cao_(excel)")}
            typeButton={"excel"}
            color={"primary"}
            variant={"outlined"}
            disabled={loadingRecGrid || iPermission < 1}
            style={{textTransform: "uppercase"}}
            size={"large"}
            onClick={onExportExcel}
        />
    };

    const renderPieChartContent = ({dataSources}) => {
        return (
            <>
                {loadingRecTotalGrid ?
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Skeleton variant={"circle"} width={350} height={350}/>
                    </div> :
                    <PieChart
                        id="pie"
                        palette="Bright"
                        onPointHoverChanged={onPointHoverChanged}
                        dataSource={dataSources}
                    >
                        <Series argumentField="RecSourceName" valueField="NumCan">
                            <Label position="columns"
                                   customizeText={(pointInfo) => `${pointInfo.valueText} (${pointInfo.percentText})`}
                                   visible={true}>
                                <Connector visible={true} width={1}/>
                            </Label>
                        </Series>
                        <Tooltip
                            enabled={false}
                            customizeTooltip={(pointInfo) => ({text: `${pointInfo.argumentText}`})}
                        />

                        <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                        />
                    </PieChart>
                }
            </>)
    }

    return (
        <>
            <ActionToolbar className={classes.actionToolbar}
                           alignment="flex-end"
                           title={Config.lang("DHR_Bao_cao_tuyen_dung")}>
                <div className={classes.actionToolbarDate}>
                    <label style={{marginTop: 11}}>{Config.lang("DHR_Tu")}</label>
                    <DateBoxPicker
                        dateBoxProps={{max: dateTo, min: moment(dateTo).subtract(60, 'days')}}
                        placeholder={Config.lang("dd/mm/yyyy")}
                        useMaskBehavior={true}
                        shrink={true}
                        value={dateFrom}
                        onValueChanged={(e) => handleChangeDateBox(e, "dateFrom")}
                        margin={"normal"}
                    />
                </div>
                <div className={classes.actionToolbarDate}>
                    <label style={{marginTop: 11}}>{Config.lang("DHR_Den")}</label>
                    <DateBoxPicker
                        dateBoxProps={{min: dateFrom, max: moment(dateFrom).add(60, 'days')}}
                        placeholder={Config.lang("dd/mm/yyyy")}
                        useMaskBehavior={true}
                        shrink={true}
                        value={dateTo}
                        onValueChanged={(e) => handleChangeDateBox(e, "dateTo")}
                        margin={"normal"}
                    />
                </div>
                <div className={classes.actionToolbarDate}>
                    <ButtonGeneral
                        loading={loadingPdf}
                        name={Config.lang("DHR_Xuat_PDF")}
                        style={{textTransform: "uppercase", marginLeft: 33, marginTop: 8}}
                        size={"large"}
                        variant={"outlined"}
                        color={"primary"}
                        icon={<Icons className="fas fa-file-pdf"/>}
                        onClick={onExportPDF}
                    />
                </div>
            </ActionToolbar>
            <Row id="containerToPrint" className={classes.rowContainer}>
                <FormGroup>
                    <div className={classes.contentContainer}>
                        {renderCard("29%", dataRecruitmentInfo, renderInfoContent, renderInfoAction)}
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className={classes.contentContainer}>
                        {renderCard("49%", [dataRecruitmentInfoByDay, dataRecruitmentInfoByWeek], renderBarChartContent)}
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className={classes.contentContainer}>
                        {renderCard("100%", dataRecruitmentGrid, renderGridContent, renderGridAction)}
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className={classes.contentContainer}>
                        {renderCard(
                            "69%",
                            dataRecruitmentTotal,
                            renderGridTotalContent
                        )}
                        {renderCard(
                            "30%",
                            dataRecruitmentTotal,
                            renderPieChartContent
                        )}
                    </div>
                </FormGroup>
            </Row>
        </>
    )
}

export default compose(connect((state) => ({
    iPermission: state.general.iPermission
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W25F4000);