
import React                         from "react";
import {connect}                     from "react-redux";
import PropTypes                     from "prop-types";
import * as W25F2303Actions          from "../../../../redux/W2X/W25F2303/W25F2303_actions";
import * as generalActions           from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config                        from "../../../../config";
import {Row, Col, FormGroup}  from "react-bootstrap"
import ButtonGeneral                 from "../../../common/button/button-general";
import withStyles                    from "@material-ui/core/styles/withStyles";
import {Combo, TextField}            from "../../../common/form-material";
import DateBoxPicker                                                              from "../../../common/form-material/date-box";
import Modal                                                                      from "../../../common/modal/modal";
import {Radio, RadioGroup, FormControlLabel, FormControl, Typography, InputLabel, FormHelperText, TextField as TextFieldM} from '@material-ui/core';
import { Editor }                                                                 from '@tinymce/tinymce-react';
import Attachments                                                                from "../../../common/attachments/attachments";
import MForm                         from "../../../common/form-material/form";
import UserImage                     from "../../../common/user/user-image";
import {TextBox}                     from "devextreme-react";
import moment                        from "moment";
import InlineSVG from "react-inlinesvg";
import InputMask from "react-input-mask";
const styles = () => ({
    mailEditor: {
        width: "100%",
    },
    paddingTitle: {
        padding: "15px 0 0 0",
    },
    labelStyle: {
        fontSize: 12,
        fontWeight: 700,
        color: "#555868"
    },
    paddingRadio: {
        '& .MuiIconButton-root': {
            padding: '10px 10px 10px 0'
        }
    },
    textBox: {
        width: 'calc(100% - 32px)'
    },
    buttonDatebox: {
        '& .dx-button-content > i': {
            fontSize: 20
        }
    },
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },

});
class W25F2303 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            RecInfoID: "",
            CandidateID: [],
            InterviewerList: [],
            InterviewType: 1,
            CanConfirmStatusID: "",
            InterviewFileID: "",
            SendMailMode: 1,
            InterviewTitle: "",
            CandidateEmail: "",
            InterviewerMail: [],
            InterviewDate: "",
            InterviewTime: null,
            Minutes: "",
            InterviewPlace: "",
            TemplateMailID: "",
            TitleMail: "",
            uploading: false,
            dataOldAttachments: [],
            dataItemCandidate: {},
            Employee: [],
            loadingCboCandidate: false,
            loadingListInterview: false,
            loadingAttachments: false,
            loadingCboMailTemplate: false,
        };
        this.interviewType = [
            {interviewTypeID : 1, interviewTypeNameVI: "Phỏng vấn tại văn phòng", interviewTypeNameEN: "ONSITE"},
            {interviewTypeID : 2, interviewTypeNameVI: "Phỏng vấn online", interviewTypeNameEN: "VIDEO"},
            {interviewTypeID : 3, interviewTypeNameVI: "Phỏng vấn qua điện thoại", interviewTypeNameEN: "PHONE"},
        ];
        this.attachments = [];
        this.deletedFile = [];
        this.setData = false;
        this.timer = null;
        this.refEditorNoteInteranal = null;
        this.refEditorContent = null;
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: data?.InterviewFileID || "",
            TableName: "D25T2303"
        };
        this.setState({loadingAttachments: true});
        this.props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            this.setState({loadingAttachments: false});
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    loadCboCandidate = () => {
        const {FormID, data, RecInfoID, CandidateID} = this.props;
        const params = {
            FormID: FormID || "",
            RecInfoID: data?.RecInfoID || RecInfoID || "",
            CandidateID: data?.CandidateID || CandidateID || ""
        };
        this.setLoading('CboCandidate', true);
        this.props.w25F2303Actions.getCboCandidate(params,(errors) => {
            this.setLoading('CboCandidate', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadCboMailTemplate = () => {
        this.setLoading('CboMailTemplate', true);
        this.props.w25F2303Actions.getCboMailTemplate((errors) => {
            this.setLoading('CboMailTemplate', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadDataForm =  () => {
        const {data} = this.props;
        const params = {
            FormID: "W25F2303",
            RecInfoID: data?.RecInfoID || "",
            CandidateID: data?.CandidateID || "",
            InterviewFileID: data?.InterviewFileID || "",
        };
        this.setLoading('DataForm', true);
        this.props.w25F2303Actions.getDataForm(params,(errors,dataForm) => {
            this.setLoading('DataForm', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(dataForm) {
                const typeInterview =  this.interviewType.filter(val => val.interviewTypeNameEN === dataForm.InterviewType);
                if(this.refEditorNoteInteranal) {
                    this.refEditorNoteInteranal.setContent(dataForm?.NoteInteranal || "");
                }
                if(this.refEditorContent) {
                    this.refEditorContent.setContent(dataForm?.Content || "");
                }
                this.setState({
                    CandidateID: data?.CandidateID || "",
                    dataItemCandidate: {
                        CandidateID: data?.CandidateID || "",
                        CandidateEmail: data?.CandidateEmail || dataForm?.CandidateEmail || "",
                        RecInfoID: data.RecInfoID || "",
                    },
                    CanConfirmStatusID: data?.CanConfirmStatusID || "",
                    BirthDate: data?.BirthDate || null,
                    InterviewFileID: data?.InterviewFileID || "",
                    InterviewTitle: dataForm?.InterviewTitle,
                    InterviewPlace: dataForm?.InterviewPlace || "",
                    CandidateName: dataForm?.CandidateName || "",
                    InterviewDate:  dataForm?.InterviewDate || null,
                    InterviewTime:  dataForm?.InterviewDate ? moment.utc(dataForm.InterviewDate).format("HH:mm") : null,
                    InterviewType: typeInterview && typeInterview.length > 0 ? typeInterview[0].interviewTypeID : 1,
                    InterviewerList: dataForm.InterviewerList ? dataForm.InterviewerList.replace(/,/g, ';').split(';') : [],
                    Minutes: dataForm?.Minutes || "",
                    Mobile: dataForm?.Mobile || "",
                    SendMailMode:  dataForm?.SendMailMode ||  dataForm?.SendMailMode === 0 ? dataForm.SendMailMode : 1,
                });
            }
        });
    };

    loadListInterview = () => {
        this.setLoading('ListInterview', true);
        this.props.w25F2303Actions.getListInterview((errors) => {
            this.setLoading('ListInterview', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    resetForm = () => {
        this.setState({
            error: {},
            RecInfoID: "",
            CandidateID: [],
            InterviewerList: [],
            InterviewType: 1,
            CanConfirmStatusID: "",
            InterviewFileID: "",
            SendMailMode: 1,
            InterviewTitle: "",
            CandidateEmail: "",
            InterviewerMail: [],
            InterviewDate: "",
            InterviewTime: null,
            Minutes: "",
            InterviewPlace: "",
            TemplateMailID: "",
            TitleMail: "",
            uploading: false,
            dataOldAttachments: [],
            dataItemCandidate: {},
            Employee: [],
        });
        this.attachments = [];
        this.deletedFile = [];
        this.refEditorNoteInteranal = null;
        this.refEditorContent = null;
        this.setData = false;
    };

    onClose = (isSaved = false) => {
        const {onClose, FormID} = this.props;
        if(FormID === "W25F2018") this.resetForm();
        if (onClose) onClose(isSaved);
    };


    onAdd = () => {
        const { getListInterviews, mode } = this.props;
        const { InterviewType, InterviewerList, CanConfirmStatusID,
                  InterviewTitle, InterviewDate, Minutes,
                  InterviewPlace, SendMailMode, InterviewTime, dataItemCandidate, InterviewFileID} = this.state;
        const validateRules = [
            {
                name: "InterviewTitle",
                rules: "isRequired",
                value: InterviewTitle
            },
            {
                name: "InterviewType",
                rules: "isRequired",
                value: InterviewType
            },
            {
                name: "InterviewPlace",
                rules: "isRequired",
                value: InterviewPlace
            },
            {
                name: "InterviewDate",
                rules: "isRequired",
                value: InterviewDate
            },
            {
                name: "Minutes",
                rules: "isRequired",
                value: Minutes
            },
            {
                name: "InterviewerList",
                rules: "isRequired",
                value: InterviewerList.length > 0
            }

        ];

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        }

        if(SendMailMode !== 0 && this.refEditorContent && !this.refEditorContent.getContent()) {
            const message = Config.lang("DHR_Noi_dung_mail_khong_duoc_de_trong");
            Config.popup.show("INFO",message);
            return null;
        } else {
            const arrAttachments = this._getAttachments();
            let hour = InterviewTime && InterviewTime.split(":");
            let calculatorDateInterview = (hour &&
                moment(InterviewDate).add(hour[0],'h').add(hour[1], 'm').format("YYYY-MM-DD HH:mm:ss")) ||
                moment(InterviewDate).format("YYYY-MM-DD HH:mm:ss");
            let InterviewerMail = [];
            InterviewerList.forEach((userID) => {
                const interviewer =  getListInterviews.filter((val) => val.UserID === userID );
                if (interviewer && interviewer.length > 0 && interviewer[0].InterviewerMail !== "") {
                    InterviewerMail.push(interviewer[0].InterviewerMail)
                };
            });
            const  interviewTypeName =  this.interviewType.filter(val => val.interviewTypeID === InterviewType);
            let params = {
                Mode: mode === "edit" ? 1 : 0,
                RecInfoID: dataItemCandidate && dataItemCandidate.RecInfoID ? dataItemCandidate.RecInfoID  : "",
                CandidateID: dataItemCandidate && dataItemCandidate.CandidateID ? dataItemCandidate.CandidateID  : "",
                CandidateName: dataItemCandidate && dataItemCandidate.CandidateName ? dataItemCandidate.CandidateName : "",
                RecpositionName: dataItemCandidate && dataItemCandidate.RecpositionName ? dataItemCandidate.RecpositionName  : "",
                InterviewerList:  InterviewerList ? JSON.stringify(InterviewerList) : "",
                NoteInteranal: this.refEditorNoteInteranal ? this.refEditorNoteInteranal.getContent() : "",
                InterviewType: interviewTypeName && interviewTypeName.length > 0 && interviewTypeName[0].interviewTypeNameEN ? interviewTypeName[0].interviewTypeNameEN : "",
                CanConfirmStatusID: CanConfirmStatusID ? CanConfirmStatusID : "",
                SendMailMode: SendMailMode ? SendMailMode : 0,
                InterviewFileID: InterviewFileID ? InterviewFileID : "",
                InterviewTitle: InterviewTitle ? InterviewTitle : "",
                CandidateEmail: dataItemCandidate && dataItemCandidate.CandidateEmail ? dataItemCandidate.CandidateEmail : "",
                InterviewerMail: InterviewerMail ? JSON.stringify(InterviewerMail) : [],
                Content: this.refEditorContent ? this.refEditorContent.getContent() : "",
                InterviewDate: calculatorDateInterview,
                Minutes: Minutes ? String(Minutes) : 0,
                InterviewPlace: InterviewPlace ? InterviewPlace : "",
                arrAttachment: arrAttachments ? JSON.stringify(arrAttachments) : ""
            };

            this.props.w25F2303Actions.save(params, (errors, data) => {
                if (errors) {
                    if(params.CandidateEmail === "") {
                        let message = Config.lang("DHR_Ung_vien_khong_co_email");
                        Config.popup.show('ERROR', message);
                        return false;
                    }
                    let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('ERROR', message);
                    return false;
                }
                if(data.Status === 1) {
                    if(data.MsgAsk === 0) {
                        let message = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    } else if (data.MsgAsk === 1) {
                        let message = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                        Config.popup.show('YES_NO', message , () => {
                            params = {...params,code: data.code};
                            this._saveData(params);
                        });
                    }
                }
                if(data.Status === 0) {
                    Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                    this.onClose(true);

                }
            });
        }
    };

    _saveData = (params) => {
        this.props.w25F2303Actions.confirm(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
            if(data.Status === 0) {
                Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                this.onClose(true);

            }
        });
    };

    renderFieldInterviewer = (data) => {
        const {classes} = this.props;
        if (data) {
            return (
                <div className={"display_row align-right"} style={{width: "100%", height: '100%', padding: "5px 0"}}>
                    {data && data.map((d, idx) => {
                        return <div key={idx}>
                            <UserImage data={d} width={32}
                                       height={32} keyExpr={"UserID"}
                                       valueExpr={"UserID"}
                                       renderItemHover={user => {
                                           const dateJoined = user ? moment(user.DateJoined).isValid() ? moment(user.DateJoined).format('DD/MM/YYYY') : user.DateJoined : "";
                                           return (
                                               <div className={""}>
                                                   {user && user.EmployeeID && <span><b>{user.EmployeeID + " - " + user.EmployeeName}</b><br/></span>}
                                                   {user && user.DepartmentName && <span>{Config.lang('DHR_Phong_ban') + ': ' + user.DepartmentName}<br/></span>}
                                                   {user && user.ProjectName && <span>{Config.lang('DHR_Du_an') + ': ' + user.ProjectName}<br/></span>}
                                                   {user && user.DutyName && <span>{Config.lang('DHR_Chuc_vu') + ': ' +user.DutyName}<br/></span>}
                                                   {user && user.DateJoined && <span>{Config.lang('DHR_Ngay_vao_lam') + ': ' + dateJoined}</span>}
                                               </div>
                                           );
                                       }}
                            />
                        </div>
                    })}
                    <TextBox className={classes.textBox}
                             defaultValue={""}
                             placeholder={data && data.length < 1 ? Config.lang('DHR_Nguoi_phong_van') : ""}
                             readOnly={false}
                             onKeyDown={this.onTextBoxKeyDown}
                    />
                </div>
            );
        }
    };

    renderInterviewer = (data) => {
        const dataInterviewer =  Config.getUser({"UserID": data["UserID"]});
        if (data) {
            return (
                <div className={"display_row align-center"}>
                    <UserImage data={data} keyExpr={"UserID"} valueExpr={"UserID"} />
                    <div className="cbo-employee-name">
                        {dataInterviewer && dataInterviewer.EmployeeName && <span>{dataInterviewer.EmployeeName} {dataInterviewer.Email && "(" + dataInterviewer.Email + ")"}<br/></span>}
                        {dataInterviewer && dataInterviewer.DepartmentName && <span>{dataInterviewer.DepartmentName}<br/></span>}
                    </div>
                </div>
            );
        }
    };

    onTextBoxKeyDown = (e) => {
        if (!e || !e.event || !e.event.target) return false;
        const evt = e.event;
        if (evt.keyCode === 8 && !evt.target.value) {
            let ListUserID = JSON.parse(JSON.stringify(this.state.InterviewerList));
            ListUserID.pop();
            setTimeout(() => {
                this.handleChange("UserID", {value: ListUserID});
            }, 300);
        }
    };

    componentDidMount() {
        const { mode} = this.props;
        this.loadCboCandidate();
        this.loadListInterview();
        this.loadCboMailTemplate();
        if(mode === "edit") {
            this.loadDataForm();
            this.loadAttachments();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mode, FormID, data, getCboCandidates} = this.props;
        if(getCboCandidates && getCboCandidates.length > 0) {
            if(FormID === "W25F2023") {
                if(!this.setData && mode === "add" && data && data.CandidateID !== "") {
                    const itemCandidate = getCboCandidates.find(item => item.CandidateID === data.CandidateID);
                    this.setState({
                        CandidateID: itemCandidate?.CandidateID ? (Array.isArray(itemCandidate.CandidateID) ? itemCandidate.CandidateID :  [itemCandidate.CandidateID]) : [],
                        dataItemCandidate : itemCandidate,
                        InterviewTitle:itemCandidate?.InterviewTitle || "",
                        InterviewPlace:itemCandidate?.InterviewPlace || ""
                    }, () => {
                        this.setData = true;
                    });
                }
            }
        }
    }

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };


    handleChange = (key, e) => {
        if (!key) return false;
        const {getCboCandidates, getCboMailTemplates} = this.props;
        switch (key) {
            case "CandidateID":
                this.setState({CandidateID: e.value}, () => {
                   let itemCandidate =  getCboCandidates.find(val => val.CandidateID === e.value);
                    this.setState({
                        dataItemCandidate : itemCandidate,
                        InterviewTitle:itemCandidate?.InterviewTitle || "",
                        InterviewPlace:itemCandidate?.InterviewPlace || ""
                    });
                });
                break;
            case "interviewTypeID":
                this.setState({InterviewType: e.value});
                break;
            case "InterviewTitle":
                this.setState({InterviewTitle: e.target.value});
                break;
            case "InterviewDate":
                this.setState({InterviewDate: e.value});
                break;
            case "Minutes":
                this.setState({Minutes: e.target.value});
                break;
            case "InterviewPlace":
                this.setState({InterviewPlace: e.target.value});
                break;
            case "SendMailMode":
                this.setState({SendMailMode: Number(e.target.value)});
                break;
            case "TemplateMailID":
                this.setState({TemplateMailID: e.value}, () => {
                    let itemMail =  getCboMailTemplates.find(val => val.TemplateMailID === e.value);
                    this.setState({TitleMail: itemMail?.TitleMail || ""}, () => {
                        if(this.refEditorContent) this.refEditorContent.setContent(itemMail?.ContentMail || "")
                    });
                });
                break;
            case "TitleMail":
                this.setState({TitleMail: e.target.value});
                break;
            // case "Content":
            //     if (this.timer) clearTimeout(this.timer);
            //     this.timer = setTimeout(() => {
            //         this.setState({content: e});
            //     }, 500);
            //     break;
            // case "NoteInteranal":
            //     if (this.timer) clearTimeout(this.timer);
            //     this.timer = setTimeout(() => {
            //         this.setState({noteInteranal: e});
            //     }, 500);
            //     break;
            case "InterviewTime":
                let timeInterview = e.target.value;
                this.setState({InterviewTime: timeInterview}, () => {
                    let checkTime = this.state.InterviewTime.split('');
                    if(!checkTime.some(val => val === "_")) {
                        this.setStateErrorText({errorMask: ""});
                    }
                });
                break;
            case "EmployeeID":
                this.setState({Employee: e.value});
                break;
            case "UserID":
                this.setState({InterviewerList: e.value});
                break;
            default:
                break;
        }
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    _checkMaskRules = (data) => {
        if(!data) return null;
        return data?.toString().startsWith('2');
    };

    render() {
        const {classes, open, getCboCandidates, getListInterviews, mode, getCboMailTemplates} = this.props;
        const { uploading, error, loading, dataOldAttachments, CandidateID, InterviewType,
                  InterviewDate,Minutes, disabled, SendMailMode ,InterviewerList, loadingCboCandidate,
                  loadingListInterview, InterviewTitle, InterviewPlace, InterviewTime, TemplateMailID, TitleMail, loadingAttachments, loadingCboMailTemplate} = this.state;
        let ListUserID = getListInterviews ? getListInterviews : "";
        ListUserID = ListUserID
            ? (Array.isArray(ListUserID) ? ListUserID : getListInterviews.replace(/,/g, ';').split(';'))
            : [];
        const checkRuleInterviewTime = InterviewTime && this._checkMaskRules(InterviewTime);
        return (
            <React.Fragment>
                <Modal open={open}  maxWidth={"md"} onClose={() => this.onClose(false)} fullWidth={true}  title={Config.lang("DHR_Them_phong_van_moi")}>
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Row>
                                                    <Col sx={12} sm={4} md={4} lg={4}>
                                                        <Typography className={classes.paddingTitle + " " + classes.labelStyle} align={"left"} component={"h6"}>{Config.lang('DHR_Ung_vien')}</Typography>
                                                    </Col>
                                                    <Col sx={12} sm={8} md={8} lg={8}>
                                                        <Combo
                                                            dataSource={getCboCandidates}
                                                            displayExpr={'CandidateName'}
                                                            valueExpr={'CandidateID'}
                                                            margin={"dense"}
                                                            selectProps={{
                                                                readOnly: mode === "edit"
                                                            }}
                                                            acceptCustomValue={false}
                                                            itemRender={(data) => {
                                                                if (!data) return null;
                                                                return (
                                                                    <div className={"display_row align-center"}>
                                                                        <div className={classes.divAvatar}>
                                                                            {data && data.CadidatePictureURL ? (
                                                                                <UserImage className={classes.imgAvatar} src={data.CadidatePictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.CadidatePictureURL : data.CadidatePictureURL}/>
                                                                            ) : (
                                                                                <InlineSVG className={classes.imgAvatar}
                                                                                           src={require('../../../../assets/images/general/user_default.svg')}/>
                                                                            )}
                                                                        </div>
                                                                        <div className="cbo-employee-name">
                                                                            {(data && data.CandidateName) || ""}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                            showClearButton={true}
                                                            loading={loadingCboCandidate}
                                                            value={CandidateID}
                                                            stylingMode={'underlined'}
                                                            placeholder={Config.lang('DHR_Ung_vien')}
                                                            onValueChanged={(e) => this.handleChange("CandidateID", e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Row>
                                                    <Col sx={12} sm={4} md={4} lg={4}>
                                                        <Typography className={classes.paddingTitle + " " + classes.labelStyle} align={"left"} component={"h6"}>{Config.lang('DHR_Loai_phong_van')}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                                    </Col>
                                                    <Col sx={12} sm={8} md={8} lg={8}>
                                                        <Combo
                                                            error={error && error["InterviewType"]}
                                                            dataSource={this.interviewType}
                                                            displayExpr={e => {
                                                                if(!e) return;
                                                                let name = "";
                                                                if(Config.language === '84') {
                                                                    name = e.interviewTypeNameVI;
                                                                } else {
                                                                    name = e.interviewTypeNameEN;
                                                                }
                                                                return name;
                                                            }}
                                                            valueExpr={'interviewTypeID'}
                                                            margin={"dense"}
                                                            value={InterviewType}
                                                            stylingMode={'underlined'}
                                                            placeholder={Config.lang('DHR_Loai_phong_van')}
                                                            onValueChanged={(e) => this.handleChange("interviewTypeID", e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Row>
                                                    <Col sx={12} sm={4} md={4} lg={4}>
                                                        <Typography className={classes.paddingTitle + " " + classes.labelStyle} align={"left"} component={"h6"}>{Config.lang('DHR_Tieu_de_phong_van')}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                                    </Col>
                                                    <Col sx={12} sm={8} md={8} lg={8}>
                                                        <TextField
                                                            error={error && error["InterviewTitle"]}
                                                            variant={"standard"}
                                                            required={true}
                                                            value={InterviewTitle}
                                                            onChange={e => this.handleChange("InterviewTitle", e)}
                                                            fullWidth
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>

                                                <Row>
                                                    <Col xs={12} sm={4} md={4} lg={4}>
                                                        <Typography className={classes.paddingTitle + " " + classes.labelStyle} align={"left"} component={"h6"}>{Config.lang('DHR_Thoi_gian_phong_van')}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                                    </Col>
                                                    <Col xs={12} sm={8} md={8} lg={8}>
                                                            <FormControl variant={"standard"}
                                                                         fullWidth={true}>
                                                                <Row>
                                                                    <Col xs={12} sm={4} md={4} lg={4}>
                                                                        <div className={"display_row align-center"}>
                                                                            <DateBoxPicker
                                                                                error={error && error["InterviewDate"]}
                                                                                stylingMode={"underlined"}
                                                                                placeholder={"dd/mm/yyyy"}
                                                                                useMaskBehavior={true}
                                                                                showClearButton={true}
                                                                                value={InterviewDate}
                                                                                width={"100%"}
                                                                                onValueChanged={e => this.handleChange("InterviewDate", e)}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} sm={4} md={4} lg={4}>
                                                                    <FormControl variant={"standard"}
                                                                                 style={{marginTop:2}}
                                                                                 error={error && !!error["errorMask"]}
                                                                                 fullWidth={true}>
                                                                        <InputLabel
                                                                            shrink={true}
                                                                        >
                                                                            {""}
                                                                        </InputLabel>
                                                                        <div className={"display_row align-center"}>
                                                                            <InputMask
                                                                                mask={'12:34'}
                                                                                maskChar={"_"}
                                                                                alwaysShowMask={true}
                                                                                formatChars={{
                                                                                    '1': '[0-2]',
                                                                                    '2':  checkRuleInterviewTime ? '[0-3]' : '[0-9]',
                                                                                    '3': '[0-5]',
                                                                                    '4': '[0-9]',
                                                                                }}
                                                                                onBlur={() => {
                                                                                    if(InterviewTime) {
                                                                                        if(InterviewTime.split('').some(val => val === "_")) {
                                                                                            this.setStateErrorText({errorMask: Config.lang('DHR_Ban_phai_nhap_dung_dinh_dang_vd_11:11')});
                                                                                        } else {
                                                                                            this.setStateErrorText({errorMask: ""});
                                                                                        }
                                                                                    }

                                                                                }}
                                                                                // disabled={loadingCheckTime || loading}
                                                                                value={InterviewTime || ""}
                                                                                onChange={(e) => this.handleChange("InterviewTime",e)}
                                                                            >
                                                                                {(props) => <TextFieldM {...props} variant={"standard"} />}
                                                                            </InputMask>
                                                                        </div>
                                                                        <FormHelperText style={{whiteSpace: "nowrap"}} >{error && !!error["errorMask"] && Config.lang("DHR_Ban_phai_nhap_dung_dinh_dang_vd_11:11")}</FormHelperText>
                                                                    </FormControl>
                                                                    </Col>
                                                                    <Col sx={12} sm={4} md={4} lg={4}>
                                                                        <div className={"display_row align-center"}>
                                                                            <TextField
                                                                                error={error && error["Minutes"]}
                                                                                size={"small"}
                                                                                variant={"standard"}
                                                                                required={true}
                                                                                type={"number"}
                                                                                value={String(Minutes)}
                                                                                inputProps={{ min: 0 }}
                                                                                style={{marginTop: 10}}
                                                                                onChange={e => this.handleChange("Minutes", e)}
                                                                                fullWidth
                                                                            />
                                                                            <span>{Config.lang("DHR_phut")}</span>
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                            </FormControl>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Row>
                                                    <Col sx={12} sm={4} md={4} lg={4}>
                                                        <Typography className={classes.paddingTitle + " " + classes.labelStyle} align={"left"} component={"h6"}>{Config.lang('DHR_Dia_diem_phong_van')}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                                    </Col>
                                                    <Col sx={12} sm={8} md={8} lg={8}>
                                                        <TextField
                                                            error={error && error["InterviewPlace"]}
                                                            variant={"standard"}
                                                            required={true}
                                                            value={InterviewPlace}
                                                            onChange={e => this.handleChange("InterviewPlace", e)}
                                                            fullWidth
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Row>
                                                    <Col sx={12} sm={4} md={4} lg={4}>
                                                        <Typography className={classes.paddingTitle + " " + classes.labelStyle} align={"left"} component={"h6"}>{Config.lang('DHR_Nguoi_phong_van')}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                                    </Col>
                                                    <Col sx={12} sm={8} md={8} lg={8}>
                                                        <Combo
                                                            error={error && error["InterviewerList"]}
                                                            dataSource={Config.storeDataSoureDevExtreme(ListUserID || [])}
                                                            displayExpr={"EmployeeName"}
                                                            valueExpr={'UserID'}
                                                            value={InterviewerList}
                                                            multiple={true}
                                                            shrink={true}
                                                            stylingMode={'underlined'}
                                                            margin={"dense"}
                                                            acceptCustomValue={false}
                                                            loading={loadingListInterview}
                                                            fieldRender={this.renderFieldInterviewer}
                                                            itemRender={this.renderInterviewer}
                                                            placeholder={Config.lang('DHR_Nguoi_phong_van')}
                                                            onValueChanged={(e) => this.handleChange("UserID", e)}
                                                        />

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Row>
                                                    <Col sx={12} sm={4} md={4} lg={4}>
                                                        <Typography className={classes.paddingTitle + " " + classes.labelStyle} align={"left"} component={"h6"}>{Config.lang('DHR_Nguoi_nhan_email')}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                                    </Col>
                                                    <Col sx={12} sm={8} md={8} lg={8}>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup aria-label="gender" className={classes.paddingRadio}  value={SendMailMode} onChange={e => this.handleChange("SendMailMode", e)}>
                                                                <FormControlLabel value={3} control={<Radio style={{fontSize:16,fontWeight:500}} />} label={Config.lang("DHR_Ung_vien_va_nguoi_phong_van")} />
                                                                <FormControlLabel value={1} control={<Radio style={{fontSize:16,fontWeight:500}}/>} label={Config.lang("DHR_Chi_ung_vien")} />
                                                                <FormControlLabel value={2} control={<Radio style={{fontSize:16,fontWeight:500}}/>} label={Config.lang("DHR_Chi_nguoi_phong_van")} />
                                                                <FormControlLabel value={0} control={<Radio style={{fontSize:16,fontWeight:500}}/>} label={Config.lang("DHR_Khong_gui_email")} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 15}}>
                                        <Row>
                                            <Col sx={12} sm={4} md={4} lg={4}>
                                                <Combo
                                                    // error={error && error["InterviewerList"]}
                                                    dataSource={getCboMailTemplates}
                                                    displayExpr={"TemplateMailName"}
                                                    valueExpr={'TemplateMailID'}
                                                    value={TemplateMailID}
                                                    shrink={true}
                                                    stylingMode={'underlined'}
                                                    margin={"dense"}
                                                    acceptCustomValue={false}
                                                    loading={loadingCboMailTemplate}
                                                    placeholder={Config.lang('DHR_Chon_mau_mail_gui_cho_ung_vien')}
                                                    onValueChanged={(e) => this.handleChange("TemplateMailID", e)}
                                                />

                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 15}}>
                                        <Row>
                                            <Col sx={12} sm={12} md={12} lg={12}>
                                                <TextField
                                                    placeholder={Config.lang("DHR_Tieu_de_mail")}
                                                    variant={"outlined"}
                                                    value={TitleMail}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onChange={(e) => this.handleChange("TitleMail", e)}
                                                    fullWidth
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 15}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Typography style={{marginBottom: 15,fontSize: 12, fontWeight:700, color:"#555868"}} align={"left"} component={"h6"}>{Config.lang('DHR_Email_gui_den_ung_vien')}</Typography>
                                                <Editor
                                                    // value={content}
                                                    init={{
                                                        height: 360,
                                                        plugins: "advlist lists link image",
                                                        toolbar:
                                                            "styleselect | bold italic forecolor | bullist numlist | link image| removeformat",
                                                        menubar: false,
                                                    }}
                                                    onInit={(e, editor) => this.refEditorContent = editor }
                                                    // onEditorChange={(e) => this.handleChange("Content", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 15}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Typography style={{marginBottom: 15, fontSize: 12, fontWeight:700, color:"#555868"}} align={"left"} component={"h6"}>{Config.lang('DHR_Ghi_chep_noi_bo')}</Typography>
                                                <Editor
                                                    init={{
                                                        height: 360,
                                                        plugins: "advlist lists link image",
                                                        toolbar:
                                                            "styleselect | bold italic forecolor | bullist numlist | link image| removeformat",
                                                        menubar: false,
                                                    }}
                                                    onInit={(e, editor) => this.refEditorNoteInteranal = editor }
                                                    // value={noteInteranal}
                                                    // onEditorChange={(e) => this.handleChange("NoteInteranal", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{ margin: "25px 0 35px 0"}}>
                                        <Attachments
                                            ref={ref => this.attRef = ref}
                                            showButton={true}
                                            files={dataOldAttachments}
                                            disabled={disabled || loading || uploading || loadingAttachments}
                                            maxLength={5}
                                            uploading={loading}
                                            onUploading={this.onUploading}
                                            onChanged={this.onChangeAttachments}
                                        />
                                    </FormGroup>
                                    <FormGroup className={"display_row align_center valign-bottom"}>
                                        <ButtonGeneral name={Config.lang('DHR_Luu')}
                                                       typeButton={'save'}
                                                       style={{textTransform: 'uppercase'}}
                                                       size={"large"}
                                                       onClick={this.onAdd}/>
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

W25F2303.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChosen: PropTypes.func,
    RecInfoID: PropTypes.string,
    data: PropTypes.object,
    mode: PropTypes.string,
};

export default compose(connect((state) => ({
    getCboCandidates: state.W25F2303.getCboCandidate,
    getAttachmentsByTransID: state.general.getAttachmentsByTransID,
    getListInterviews: state.W25F2303.getListInterviews,
    getCboMailTemplates: state.W25F2303.getCboMailTemplate
}), (dispatch) => ({
    w25F2303Actions: bindActionCreators(W25F2303Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W25F2303);